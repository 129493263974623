import ParentModel from '@/models/parent/ParentModel.js';

export default class SystemTag extends ParentModel {
   constructor(data) {
      super(data);
      if (data != null) {
         this.id = data.id;
         this.site_id = data.site_id;
         this.category_id = data.category_id;
         this.title = data.title;
         this.short_desc = data.short_desc;
         this.point_cloud_tag_id = data.point_cloud_tag_id;
         this.system_tag_id = data.system_tag_id;
         this.files = data.files;
      } else {
         this.site_id = '';
         this.system_id = '';
         this.category_id = '';
         this.title = '';
         this.short_desc = '';
         this.point_cloud_tag_id = '';
         this.system_tag_id = '';
         this.files = '';
      }
   }

   get toDto() {
      const dto = {};
      dto.site_id = this.site_id;
      dto.category_id = this.category_id;
      dto.title = this.title;
      dto.short_desc = this.short_desc;
      dto.point_cloud_tag_id = this.point_cloud_tag_id;
      dto.system_tag_id = this.system_tag_id;
      dto.files = this.files;
      return dto;
   }
}
