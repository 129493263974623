<template>
   <div class="">
      <pointcloud style="z-index: 10;"></pointcloud>
      <system style="z-index: 11;"></system>

      <v-slide-x-transition>
         <pointcloudlist v-if="(showComp=='PointCloudList')"  style="z-index: 12;"></pointcloudlist>
         <systemlist v-if="(showComp=='SystemList')"  style="z-index: 12;"></systemlist>
         <taglist v-if="(showComp=='TagList')"  style="z-index: 12;"></taglist>
      </v-slide-x-transition>

      <v-slide-x-reverse-transition>
         <formsystem v-if="systemEditItem" style="z-index: 13;" />
         <formtag v-if="tagEditItem" style="z-index: 13;" />
      </v-slide-x-reverse-transition>

      <v-slide-x-transition mode="out-in" origin="top left">
         <tagpopup />
      </v-slide-x-transition>
   </div>
</template>

<script>
/**
 * models
 */
import Pointcloud from '@/models/Pointcloud';
import System from '@/models/System';

/**
 * services
 */

/**
 * components
 */
// viewers
import ViewerPointCloud from '@/components/Viewers/PointCloud.vue';
import ViewerSystem from '@/components/Viewers/System';

// lists
import PointCloudList from '@/components/Lists/PointCloudList';
import SystemList from '@/components/Lists/SystemList';
import TagList from '@/components/Lists/TagList';

// forms
import FormSystem from '@/components/Forms/FormSystem.vue';
import FormTag from '@/components/Forms/FormTag.vue';

// Popups
import TagPopup from '@/components/Popups/TagPopup.vue';

/**
 * helpers
 */
import Util from '@/utils';
import ZManager from '@/utils/ZManager';

export default {
   components: {
      pointcloud: ViewerPointCloud,
      system: ViewerSystem,

      pointcloudlist: PointCloudList,
      systemlist: SystemList,
      taglist: TagList,

      formsystem: FormSystem,
      formtag: FormTag,

      tagpopup: TagPopup,
   },
   computed: {
      showComp() {
         return this.$store.getters.activeListComp;
      },
      pointCloudList() {
         return this.$store.getters.listPointClouds;
      },
      currentSite() {
         if (this.$store.getters.site) { return this.$store.getters.site; }
         return false;
      },

      /**
       * forms controllers
       */
      systemEditItem() {
         return this.$store.getters.systemEditItem;
      },
      tagEditItem() {
         return this.$store.getters.tagEditItem;
      },
   },
   watch: {
      pointCloudList: {
         handler(val) {
            if ((val) && (!this.$store.getters.currentPointCloud)) {
               this.$store.dispatch('setCurrentPointCloud', val[0]);
            }
         },
         deep: true,
      },
   },
   data() {
      return {
         zManager: null,
      };
   },
   mounted() {
      if (!this.currentSite) {
         this.$router.push('/');
      }
      // this.zManager = new ZManager();
      // this.zManager.addElements = this.$el.children;
      // this.zManager.addBringToTopEvent();
   },
   created() {
      this.$store.dispatch('resetActiveListComp');
      this.$store.dispatch('resetAllForms');
      // this.$store.dispatch('updatePointClouds');
   },

   methods: {

   },
};
</script>

<style>
</style>
