<template>
   <div class="sideForm" v-if="selfItem">
      <v-card>
         <v-card-title>
            {{ (!selfItem.hasId) ? 'Add a new system' : 'Edit system' }}
         </v-card-title>
         <v-card>
            <v-icon class="closeBTN" @click="closeForm"> mdi-close </v-icon>
         </v-card>
      </v-card>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
         <v-text-field
            v-model="selfItem.title"
            label="System Title"
            color="primary"
            persistent-placeholder
            required
         ></v-text-field>
         <v-textarea
            v-model="selfItem.short_desc"
            label="Short Description"
            auto-grow
            required
            persistent-placeholder
            rows="1"
         ></v-textarea>
         <v-file-input
            accept="application/pdf"
            label="Select pdf"
            prepend-icon=""
            append-icon="mdi-dots-horizontal"
            v-model="tmpimg"
            persistent-placeholder
            @change="addFiles">
         </v-file-input>
         <v-card v-if="selfItem.file_thumb" outlined color="white" class="pa-1 mt-0 mb-5">
            <v-row justify="start" no-gutters>
               <v-col cols="7">
                  <v-img class="pa-1"
                         :src="selfItem.file_thumb" ref="file"
                  ></v-img>
               </v-col>
               <v-col cols="4" class="mr-0 ml-2" >
                  <!-- no need to open file here
                     <a href="#" class="caption text-decoration-none" @click="editItem(item)" >
                        <v-icon color="primary" x-small >
                           mdi-open-in-new
                        </v-icon>
                        Open
                     </a>
                     <br/>
                     -->

               </v-col>
            </v-row>
         </v-card>
         <v-card class="formFooterButtons">
            <v-btn :disabled="!valid" color="info" @click="submit">Apply
               <v-icon
                  right
               >
                  mdi-check-bold
               </v-icon>
            </v-btn>
         </v-card>

      </v-form>
   </div>

</template>
<script>
import Util from '@/utils';
import SystemService from '@/service/SystemService';

export default {
   name: 'FormSystem',
   data() {
      return {
         util: Util,
         service: new SystemService(),
         selfItem: '',
         valid: false,
         tmpimg: null,
      };
   },
   computed: {
      item() {
         return this.$store.getters.systemEditItem;
      },
   },
   watch: {
      item(val) {
         this.selfItem = val;
      },
   },
   mounted() {
      this.selfItem = this.item;
   },
   methods: {
      addFiles() {
         this.readers = new FileReader();
         this.readers.onloadend = () => {
            const fileData = this.readers.result;
            this.selfItem.file_svg = fileData;
         };
         this.readers.readAsDataURL(this.tmpimg);
      },
      submit() {
         if (this.$refs.form.validate()) {
            Util.loading(true);
            if (this.selfItem.hasId) {
               this.service.update(this.selfItem.id, this.selfItem).then((res) => {
                  console.log('update res', res);
                   if (res.ok) {
                     //this.$mixpanel.track('System updated', {res});
                     Util.sendEvent('System', 'update', 'success', res);
                     Util.showMessage('added sucessfuly!');
                  } else {
                     //this.$mixpanel.track('System update error', { res});
                     Util.sendEvent('System', 'update', 'error', res);
                     Util.showMessage(`error adding: ${res.data.errors}`, 'error');
                  }
                  Util.showMessage('updated sucessfuly!');
                  Util.loading(false);
                  this.closeForm();
               });
            } else {
               this.service.add(this.selfItem).then((res) => {
                  console.log('add res:', res);
                  if (res.ok) {
                     //this.$mixpanel.track('System added', {res});
                     Util.sendEvent('System', 'add', 'success', res);
                     Util.showMessage('added sucessfuly!');
                  } else {
                     //this.$mixpanel.track('System add error', { res});
                     Util.sendEvent('System', 'add', 'error', res);
                     Util.showMessage(`error adding: ${res.data.errors}`, 'error');
                  }
                  Util.showMessage('added sucessfuly!');
                  Util.loading(false);
                  this.closeForm();
               });
            }
         }
      },
      clear() {
         this.$refs.form.reset();
      },
      closeForm() {
         this.$store.dispatch('setSystemItemForm', null);
         this.selfItem = null;
         this.$store.dispatch('updateSystems');
      },
   },
};
</script>
