import { render, staticRenderFns } from "./PointCloud.vue?vue&type=template&id=710e8dad&scoped=true&"
import script from "./PointCloud.vue?vue&type=script&lang=js&"
export * from "./PointCloud.vue?vue&type=script&lang=js&"
import style0 from "./PointCloud.vue?vue&type=style&index=0&id=710e8dad&lang=sass&scoped=true&"
import style1 from "./PointCloud.vue?vue&type=style&index=1&id=710e8dad&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710e8dad",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VImg,VSnackbar,VTooltip})
