<template>
   <v-card v-if="is_popup_visible" class="tagPopupContainer">
      <v-card-title :class="['title', {'titleAlert':item.title.indexOf('*')>=0}]">
         <div class="inTitle  d-flex">
            <v-img :src="getIconWithColor(item.category.icon, item.category.color)" class="ml-0 pa-0 mr-2" max-height="35" contain max-width="35"></v-img>
            <strong class="text-truncate">{{ item.title }}</strong>
         </div>
         <div class="btnContainer d-flex justify-space-between" >
            <v-tooltip open-on-hover open-delay="500" bottom >
               <template v-slot:activator="{ on, attrs }">
                  <v-icon class="smlIcon" :ripple="false" color="white" v-if="item.point_cloud_tag" @click="gotoPointCloud" v-bind="attrs" v-on="on">mdi-cube-scan</v-icon>
               </template>
               <span>Point cloud tag</span>
            </v-tooltip>
            <v-tooltip open-on-hover open-delay="500" bottom >
               <template v-slot:activator="{ on, attrs }">
                  <v-icon class="smlIcon mx-2" :ripple="false" color="white" v-if="item.system_tag.position" @click="gotoSystemTag" v-bind="attrs" v-on="on">mdi-application-array-outline</v-icon>
               </template>
               <span>System tag</span>
            </v-tooltip>
            <v-icon color="white" class="smlIcon" @click="closePopup">mdi-close</v-icon>
         </div>
      </v-card-title>
      <v-card-text >
         <div class="breadCrumbs">
            <strong>{{ item.category.parent.title }}</strong> >>  {{ item.category.title }}
         </div>
         <div class="shortDesc">
            {{ item.short_desc }}
         </div>
      </v-card-text>

      <v-card v-if="item.files.length">
         <v-card-title  class="filesTitle">Files ({{item.files.length}})</v-card-title>
         <div class="filesWindow">
            <v-card v-for="(img, imgIndex) in item.files" :key="`imgIndex${imgIndex}`">
               <a class="fileLink" :href="`${img.file}?token=${authToken}`" @click="trackClick" target="_blank">{{img.name}}
                  <v-img v-if="img.file.match(/\.(jpg|jpeg|png|gif)$/i)" class="imgFrame" :src="`${img.file}?token=${authToken}`" :title="img.name"></v-img>
               </a>
            </v-card>
         </div>
      </v-card>

      <v-card-actions class="bottomActions" v-if="(auth.role!='viewer')">
         <v-btn
            color="secondary"
            text
            plain
            @click="editCurrentTag"
         >
            <v-icon
               class="mr-0"
               left
               x-small
            >
               mdi-pencil-outline
            </v-icon>
            Edit
         </v-btn>
         <v-btn
            color="secondary"
            text
            plain
            @click="redrawCurrentTag"
         >
            <v-icon
               class="mr-0"
               left
               x-small
            >
               mdi-vector-square-edit
            </v-icon>
            Redraw
         </v-btn>
         <v-btn
            color="secondary"
            text
            plain
            @click="deleteItem"
            attrs="attrs"
         >
            <v-icon
               class="mr-0"
               left
               x-small
            >
               mdi-trash-can-outline
            </v-icon>
            Delete
         </v-btn>
      </v-card-actions>

      <v-card class="leftBtnsBar">
         <v-card class="leftBtn">
            <v-icon
               color="white"
               medium
            >
               mdi-tag-multiple
            </v-icon>
         </v-card>
        <!-- <v-card class="tagMarker">
             <svg xmlns="http://www.w3.org/2000/svg" width="9.43" height="9.43" viewBox="0 0 9.43 9.43">
            <path id="Path_2" data-name="Path 2" d="M1.32,1.132v7.43H8.75Z" transform="translate(-0.32 -0.132)" fill="#2488ff" stroke="#2488ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>

         </v-card>-->
         <!-- <v-card class="leftBtn">
            <v-icon color="white" medium>mdi-tag-multiple</v-icon>
         </v-card> -->
      </v-card>
   </v-card>

</template>

<script>
import { auth } from '@/main';
import Util from '@/utils';

export default {
   name: 'TagPopup',

   computed: {
      is_popup_visible() {
         return ((this.$store.getters.tags_isPopupVisible) && (this.$store.getters.tags_popupTagData));
      },
      item() {
         return this.$store.getters.tags_popupTagData;
      },
      currentSystem() {
         return this.$store.getters.currentSystem;
      },
      viewableTags() {
         return this.$store.getters.tags_viewableTags || [];
      },
   },

   data() {
      return {
         auth: auth,
         authToken: localStorage.getItem('token'),
      };
   },

   mounted() {

   },

   methods: {
      trackClick(e) {
      //   this.$mixpanel.track('TagPopup: File Clicked', {
      //     tag: this.item.title,
      //     file: e.target.innerText,
      //   });
         Util.sendEvent('TagPopup: File Clicked', {
            tag: this.item.title,
            file: e.target.innerText,
         });
      },
      gotoPointCloud() {
         if (!this.viewableTags.includes(this.item.id)) {
            const vTags = this.viewableTags;
            vTags.push(this.item.id);
            // this.$mixpanel.track('TagPopup: Goto Point Cloud', { tag: this.item });
            Util.sendEvent('Tag Popup', ' Goto Point Cloud', { tag: this.item });
            this.$store.dispatch('tags_updateViewableTags', vTags);
         }
         this.$store.dispatch('forceViewableTag', this.item);

         setTimeout(() => {
            this.$store.dispatch('systemView_setCurrCompSize', 'small');
            this.$store.dispatch('pointcloudView_setCurrentPointcloudTag', { tag: this.item, action: 'view' });
         }, 500);
      },

      changeSystem(system_id) {
         const item = this.$store.getters.listSystems.find((o) => o.id === system_id);
         // this.$mixpanel.track('TagPopup: Goto System', { system: item });
         Util.sendEvent('Tag Popup', 'Goto System', { system: item });
         setTimeout(() => {
            this.$store.dispatch('setCurrentSystem', item);
         }, 500); // let the animation finish before changing the system otherwise graphical glitches may occur
      },

      gotoSystemTag() {
         this.$store.dispatch('systemView_setCurrCompSize', 'large');

         const tagObj = this.$store.getters.getAllTags.find((o) => o.system_tag_id === this.item.system_tag_id);
         // this.$mixpanel.track('TagPopup: Goto System Tag', { tag: this.item });
         Util.sendEvent('Tag Popup', 'Goto System Tag', { tag: this.item });
         if (!this.currentSystem) {
            this.changeSystem(tagObj.system_tag.system_id);
            console.log('calling again', this.currentSystem);
            setTimeout(() => { this.gotoSystemTag(); }, 500);
            return;
         }
         if (this.currentSystem.id !== tagObj.system_tag.system_id) {
            console.log('calling again2', this.currentSystem);
            this.changeSystem(tagObj.system_tag.system_id);
            setTimeout(() => { this.gotoSystemTag(); }, 500);
            return;
         }
         const pos = JSON.parse(tagObj.system_tag.position);
         const moveToCords = {
            x: pos.x,
            y: pos.y,
            w: pos.width,
            h: pos.height,
         };
         setTimeout(() => {
            this.$store.dispatch('systemView_setPZMoveToTarget', moveToCords);
            this.$store.dispatch('tags_setPopupTagDataAndShow', tagObj);
            this.$store.dispatch('systemView_setCurrentSystemTagAction', 'view');
            this.$store.dispatch('systemView_setCurrentSystemTag', tagObj);
         }, 500);
      },

      editCurrentTag() {
         // this.$mixpanel.track('TagPopup: Edit Tag', { tag: this.item });
         Util.sendEvent('Tag Popup', 'Edit Tag', { tag: this.item });
         this.$store.dispatch('setTagItemForm', this.item);
         this.$store.dispatch('setCurrentSystem', this.item.system_tag.system);
      },
      deleteCurrentTag() {
         // this.$mixpanel.track('TagPopup: Delete Tag', { tag: this.item });
         Util.sendEvent('Tag Popup', 'Delete Tag', { tag: this.item });
         this.$store.dispatch('tags_deleteCurrentPopupTag');
      },
      deleteItem() {
         Util.showConfirm(`Delete ${this.item.title}`,
            `Are you sure you want to delete <strong>${this.item.title}</strong> and all its data?`,
            () => this.deleteCurrentTag(),
            () => {});
      },
      redrawCurrentTag() {
         // this.$mixpanel.track('TagPopup: Redraw Tag', { tag: this.item });
         Util.sendEvent('Tag Popup', 'Redraw Tag', { tag: this.item });
         console.log(this.$store.getters.systemView_currectCompSize);
         if ((this.$store.getters.systemView_currectCompSize === 'large') && (this.currentSystem)) {
            this.$store.dispatch('systemView_setCurrentSystemTagAction', 'add');
         } else {
            this.$store.dispatch('pointcloudView_setCurrentPointcloudTag', { tag: this.item, action: 'add' });
         }
      },
      closePopup() {
         // this.$mixpanel.track('TagPopup: Close Tag Popup', { tag: this.item });
         Util.sendEvent('Tag Popup', 'Close Tag Popup', { tag: this.item });
         this.$store.dispatch('tags_closePopup');
      },
      getIconWithColor(filename, folder) {
         return `${process.env.VUE_APP_API}/svg/${folder.replace('#', '').toLowerCase()}/${filename}.svg`;
      },
   },
   beforeDestroy: function () {
      this.closePopup();
   },
};
</script>
<style lang="sass" scoped>
.v-tooltip__content
   border: 1px solid rgba(255,255,255,0.2) !important
   background-color: #002244
</style>
<style scoped>
.tagPopupContainer {
   position:absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width:320px;
   height:500px;
   background-color: #fff !important;
   margin: auto 0 auto 60%;
   z-index: 100000;
   /*round corners*/
   border-radius: 0 0 3px 3px;
   /* add drop ahadow */
   box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.5) !important;
}
.leftBtnsBar{
   position: absolute;
   top:0;
   left:-77px;
   width:77px;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   background-color: transparent !important;
   z-index: 100000000;
}
.leftBtn {
   background-color: #002A54 !important;
   color:#fff !important;
   height: 62px;
   width: 77px;
   display: flex;
   justify-content: center
}
.tagMarker {
   /*background-color: #002A5422 !important;*/
   color:#fff !important;
   height: 150px;
   width: 77px;
   /*border-left: 2px dotted #2488FF;
   border-bottom: #2488FF 2px dotted;*/
   display: flex;
   justify-content: flex-start;
   justify-content: flex-start;
   align-items: flex-end;
   
}
.inTitle{
   display: inline-block;
   max-width: 230px;
}
.title {
   position: relative;
   width:100%;
   height: 62px;
   font-size:16px !important;
   background-color: #2488FF !important;
   color: #fff;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-left:18px;
   padding-right:18px;
}
.titleAlert{
   background-color: #FF0035 !important;
}
.breadCrumbs{
   margin-top: 10px;
   font-size: 11px !important;

   position: relative;
   width:100%;
   color: #002A54;
}
.shortDesc{
   margin-top: 10px;

   padding-right: 15px;
   font-size:13px;
   position: relative;
   width:100%;
   color: #002A54;
   text-align: justify;
   line-height: 1rem;
   height: 65px;
   overflow-y: auto;
}
.bottomActions{
   position: absolute;
   width: 100%;
   padding: 0 18px;
   justify-content: justify-start;
   text-align: ;
   bottom: 10px;
   display: flex;
}
.btn{
   cursor: pointer;
}

.btnContainer{
   width:50px;
}

.smlIcon
{
   font-size: 14px !important;
}
.fileLabel
{
         font-size:0.88rem;
         font-weight: 500;
         color: var(--v-bgdark-base) !important;
}
.filesWindow
{
   font-size:0.8rem;
   font-weight: 500;
   background-color: !important;
   color: #00B1FF !important;
   max-height:230px;
   overflow-y: scroll;
   margin-right:13px;

}
::-webkit-scrollbar-thumb
{
   color: var(--v-primary-base) !important;

}
.imgFrame
{
   background: linear-gradient(0deg, #ffffff 0%, #ffffffbb 50%, #ffffff88 100%) !important;
   max-width:70%;
   padding-left:18px;
   padding-right: 15px;
   margin-bottom: 10px;
   margin-left:18px;
}
.fileLink
{
   color: var(--v-bgdark-base) !important;
   text-decoration: none !important;
   margin-left: 20px;
}
.filesTitle
{
   color: var(--v-bgdark-base) !important;
   text-decoration: none !important;
}
</style>
