<template>
   <div class="sideForm" v-if="selfItem">
      <v-card>
         <v-card-title>
            {{ (!selfItem.hasId) ? 'Add a new tag' : 'Edit tag' }}
         </v-card-title>
         <v-card>
            <v-icon class="closeBTN" @click="closeForm"> mdi-close </v-icon>
         </v-card>
      </v-card>
      <v-divider></v-divider>
      <v-card v-if="!selfItem.hasId" class="white--text text-body-2 ma-4">Adding a new tag to {{ currentSystemTitle }}</v-card>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
         <v-text-field
            v-model="selfItem.title"
            label="Title"
            color="primary"
            persistent-placeholder
            required
            @keyup="checkShortcuts"
         ></v-text-field>
         <v-textarea
            spellcheck="true"
            v-model="selfItem.short_desc"
            label="Short description"
            required
            persistent-placeholder
            rows="4"
         ></v-textarea>
         <div v-if="(filesPointer.length)">
            <span class="fileLabel"> Files ({{filesPointer.length}})</span>
            <div class="filesWindow">
               <div  v-for="(file, index) in filesPointer" :key="`fileIndex${index}`">
                  <a class="whiteLink" :href="`${file.file}?token=${authToken}`" target="_blank">{{file.name}}</a>
                  <div class="d-flex justify-space-between">
                     <div v-if="file.file.match(/\.(jpg|jpeg|png|gif)$/i)">
                        <a :href="`${file.file}?token=${authToken}`" target="_blank">
                           <v-img class="whiteBG" contain :src="`${file.file}?token=${authToken}`" :title="file.name"></v-img>
                        </a>
                     </div>
                     <div>
                        <v-btn
                           plain
                           text
                           x-small
                           color="primary"
                           @click="removeFromfilesPointer(index)">
                           <v-icon color="primary" x-small >mdi-close-circle</v-icon>
                           remove
                        </v-btn>
                        <v-btn
                           plain
                           text
                           x-small
                           color="primary"
                           width="20px"
                           :href="`${file.file}?token=${authToken}`" target="_blank">
                           <v-icon color="primary" x-small>mdi-open-in-new</v-icon>
                           open
                        </v-btn>
                     </div>
                  </div>

                  <v-divider class="mt-2 mb-2"></v-divider>
               </div>
            </div>
            <br />
         </div>
         <div v-if="(newFilesPointer.length)">
            <span class="fileLabel" > Files to add </span>
            <div class="filesWindow">
               <div  v-for="(nfile, index) in newFilesPointer" :key="`newFileIndex${index}`">
                  <div class="d-flex justify-space-between">
                     <div>
                        <a class="whiteLink" >{{nfile.name}}</a>
                     </div>
                     <div>
                        <v-btn
                           plain
                           text
                           x-small
                           color="primary"
                           @click="removeFromNewfilesPointer(index)">
                           <v-icon color="primary" x-small >mdi-close-circle</v-icon>
                           remove
                        </v-btn>
                     </div>
                  </div>
               </div>
            </div>
            <br>
         </div>
         <v-file-input type="file"
                       accept="image/png, image/jpeg, application/pdf"
                       label="File input"
                       prepend-icon=""
                       append-icon="mdi-dots-horizontal"
                       persistent-placeholder
                       v-model="fileField"
                       @change="fileUpload"
         ></v-file-input>

         <v-select
            v-if="categories"
            :items="childrenTest(categories)"
            v-model="selectedCat"
            :menu-props="{ zIndex: 1000 }"
            item-text="title"
            persistent-placeholder
            item-value="children"
            label="Choose a category">
         </v-select>
         <v-select
            v-if="selectedCat"
            :items="selectedCat"
            v-model="selfItem.category_id"
            :menu-props="{ zIndex: 1000 }"
            item-text="title"
            item-value="id"
            persistent-placeholder
            label="Choose a sub-category">
            <template v-slot:selection="{ item }" class="">
               <v-img
                  :src="getWhiteSVGimageURL(item.icon)"
                  max-height="25"
                  max-width="25"
                  contain
                  class="mr-2"
               ></v-img>
               <span
                  class="d-inline-block text-truncate"
                  style="max-width: 180px;"
               >{{ item.title }}</span
               >
            </template>
            <template v-slot:item="{active, item, attrs, on }">
               <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                     <v-img
                        :src="getWhiteSVGimageURL(item.icon)"
                        max-height="35"
                        max-width="35"
                        contain
                     ></v-img>
                  </v-list-item-icon>
                  <v-list-item-content>
                     <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
            </template>
         </v-select>
         <div class="d-flex">
            <v-btn color="white" @click="copyToClipboard" dark icon title="copy" v-show="selfItem.hasId">
               <v-icon small>
                  mdi-content-copy
               </v-icon>
            </v-btn>
            <v-btn v-if="clipboard" color="white" @click="pasteFromClipboard" dark icon :title="`paste '${clipboard.title}'`">
               <v-icon small>
                  mdi-content-paste
               </v-icon>
            </v-btn>

            <v-card class="formFooterButtons d-flex ml-auto">

               <v-btn :disabled="!valid" color="info" @click="submit">Apply
                  <v-icon
                     right
                  >
                     mdi-check-bold
                  </v-icon>
               </v-btn>

            </v-card>
         </div>
      </v-form>
   </div>
</template>
<script>
/* models */
import Tag from '@/models/Tag';

/* services */
import TagService from '@/service/TagService';
// import CategoryService from '@/service/CategoryService.js';
import UploadFileService from '@/service/UploadFileService.js';

/* helpers */
import Util from '@/utils';

export default {
   name: 'FormTag',
   data() {
      return {
         util: Util,
         tagService: new TagService(),
         selfItem: '',
         valid: false,
         tmpimg: null,
         cats: null,
         // categories: null,
         selectedCat: null,
         selectedSubCat: null,
         currentSystemTitle: '',
         files: null,
         filesPointer: [],
         newFilesPointer: [],
         fileField: null,
         lastShortCut: '',
         authToken: localStorage.getItem('token'),
      };
   },
   computed: {
      item() {
         return this.$store.getters.tagEditItem;
      },
      currentSystem() {
         return this.$store.getters.currentSystem;
      },
      categories() {
         return this.$store.getters.listCatsByParentWithChildren;
      },
      clipboard() {
         return this.$store.getters.clipboard;
      },
   },
   watch: {
      item: {
         handler(val) {
            this.selectedCat = null;
            this.selectedSubCat = null;
            this.lastShortCut = '';
            this.selfItem = new Tag(val);
            if (this.selfItem.category_id) {
               const tmpList = this.getChosenParent(this.selfItem.category_id);
               if (typeof tmpList.children !== 'undefined') {
                  this.selectedCat = tmpList.children;
               }
            }
            this.resetFileSystem();
         },
         deep: true,
      },
      currentSystem(val) {
         this.currentSystemTitle = val.title;
      },
   },
   mounted() {
      this.$store.dispatch('updateCatsByParentWithChildren');
      // this.getCategoriesList();
      this.currentSystemTitle = this.currentSystem.title;
      this.selfItem = new Tag(this.item);
      if (this.selfItem.category_id) {
         const tmpList = this.getChosenParent(this.selfItem.category_id);
         if (typeof tmpList.children !== 'undefined') {
            this.selectedCat = tmpList.children;
         }
      }
      this.resetFileSystem();
   },
   methods: {
      copyToClipboard() {
         this.$store.dispatch('copyTagToClipboard', { ...this.selfItem });
         Util.showMessage(`Tag '${this.selfItem.title}' Copied!`, 'success');
      },
      pasteFromClipboard() {
         const tmp = { ...this.selfItem, ...this.clipboard };
         this.selfItem = new Tag(tmp);
      if (this.selfItem.category_id) {
         const tmpList = this.getChosenParent(this.selfItem.category_id);
         if (typeof tmpList.children !== 'undefined') {
            this.selectedCat = tmpList.children;
         }
      }
      this.resetFileSystem();
      },
      checkShortcuts() {
         const s = this.selfItem.title;
         const result = s.match(/(^| )(TT|PI|LS|FIT)-/);
         if (result !== null) {
            if (this.lastShortCut === result[2]) {
               return;
            }
            this.lastShortCut = result[2];
            switch (result[2]) {
            case 'TT':
               this.setShortcutCat(32);
               this.selfItem.short_desc = this.selfItem.short_desc || 'Temperature Transmitter';
               break;
            case 'LS':
               this.setShortcutCat(29);
               this.selfItem.short_desc = this.selfItem.short_desc || 'Level Switch';
               break;
            case 'PI':
               this.setShortcutCat(31);
               this.selfItem.short_desc = this.selfItem.short_desc || 'Pressure Indicator';
               break;
            case 'FIT':
               this.setShortcutCat(32);
               this.selfItem.short_desc = this.selfItem.short_desc || 'Flow Indicate Transmitter';
               break;
            default:
               break;
            }
         }
      },
      setShortcutCat(id) {
         this.selfItem.category_id = id;
         const tmpList = this.getChosenParent(id);
         if (typeof tmpList.children !== 'undefined') {
            this.selectedCat = tmpList.children;
         }
      },
      resetFileSystem() {
         this.filesPointer = [];
         if (this.selfItem) {
            if (this.selfItem.files) {
               this.filesPointer = this.selfItem.files;
            }
         }
         this.newFilesPointer = [];
      },
      removeFromNewfilesPointer(index) {
         this.newFilesPointer.splice(index, 1);
      },
      removeFromfilesPointer(index) {
         this.filesPointer.splice(index, 1);
      },
      fileUpload(event) {
         this.submitFiles(event);
      },
      submitFiles(fileitem) {
         if (fileitem) {
            const fileService = new UploadFileService();
            fileService.upload(fileitem).then((res) => {
               if (res.status === 200) {
                  this.newFilesPointer.push(res.data.file);
                  this.fileField = null;
               }
            });
         }
      },
      // getCategoriesList() {
      //    const categoryService = new CategoryService();
      //    categoryService.list().then((list) => {
      //       if (list) {
      //          this.categories = list.data.data;
      //       }
      //    });
      // },
      getChosenParent(category_id) {
         const parent_id = this.getParentIdFromCategoryId(category_id);
         if (parent_id) {
            return this.categories.find((item) => item.id === parent_id);
         }
         return null;
      },

      getParentIdFromCategoryId(categoryId) {
         const catsFullList = this.$store.getters.listCategories;
         if (catsFullList) {
            for (let i = 0; i < catsFullList.length; i += 1) {
               if (catsFullList[i].id === categoryId) {
                  return catsFullList[i].parent_id;
               }
            }
         }
         return false;
      },

      getWhiteSVGimageURL(val) {
         return `${process.env.VUE_APP_API}/svg/ffffff/${val}.svg`;
      },
      childrenTest(cats) {
         let i = 0;
         const result = [];
         for (i = 0; i < cats.length; i += 1) {
            if (typeof (cats[i].children) !== 'undefined') {
               result.push(cats[i]);
            }
         }
         return result;
      },
      submit() {
         if (this.$refs.form.validate()) {
            Util.loading(true);
            this.selfItem.new_tag_files = this.newFilesPointer;
            this.selfItem.tag_files = this.filesPointer;
            if (this.selfItem.hasId) {
               this.tagService.update(this.selfItem.id, this.selfItem).then((res) => {
                  if (!res.ok) {
                     // this.$mixpanel.track('Tag update error', {res});
                     Util.sendEvent('Tag', 'update', 'error', res);
                     Util.showMessage(`Error, ${res.data.message}`, 'error');
                  } else {
                     // this.$mixpanel.track('Tag update ', { res});
                     Util.sendEvent('Tag', 'update', 'success', res);
                     Util.showMessage('updated sucessfuly!');
                     Util.loading(false);
                     this.closeForm();
                  }
               });
            } else {
               this.tagService.add(this.selfItem, this.currentSystem.id).then((res) => {
                  if (!res.ok) {
                     // this.$mixpanel.track('Tag add error', {res});
                     Util.sendEvent('Tag', 'add', 'error', res);
                     Util.showMessage(`Error, ${res.data.message}`, 'error');
                  } else {
                     // this.$mixpanel.track('Tag added ', { res});
                     Util.sendEvent('Tag', 'add', 'success', res);
                     Util.showMessage('added sucessfuly!');
                  }

                  Util.loading(false);
                  this.closeForm();
               });
            }
         }
      },
      clear() {
         this.$refs.form.reset();
         this.selectedCat = null;
         this.selectedSubCat = null;
      },
      closeForm() {
         this.$store.dispatch('setSystemItemForm', null);
         this.selfItem = null;
         this.selectedCat = null;
         this.selectedSubCat = null;
         this.$store.dispatch('updateSystems');
         this.$store.dispatch('updateTags');
         this.$store.dispatch('updateTagTree');
      },
   },
};
</script>
<style>
::-webkit-scrollbar {
   width: 4px;
   height: 10px;
   margin-top: 0px;
 }
 ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
 }
 ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    border: 0px none #ffffff;
    border-radius: 1px;
    color:var(--v-bgoverlay-base)
 }
::-webkit-scrollbar-thumb:active {
   background: map-get($colors-alpha, 'bg-95');
 }
::-webkit-scrollbar-track {
   background: transparent;
   border: 0px none #ffffff;
   border-radius: 2px;
 }
 ::-webkit-scrollbar-track:hover {
   background: transparent;
 }
 ::-webkit-scrollbar-track:active {
   background: transparent;
 }
 ::-webkit-scrollbar-corner {
   background: transparent;
 }
 ::-webkit-scrollbar-track-piece:start {
     background: transparent;
     margin-top: 0px;
 }
</style>
