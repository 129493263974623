<template>
   <v-scroll-x-reverse-transition>
      <div class="systemViewContainer" :class="[{ cursorMove: pz_cursorMove},compSize]" ref="systemViewContainer" v-if="!!curSystem" @transitionend="pz_updatepanZoomContainerSize">
         <v-card class="appHeader" color="secondary">
            <v-card class="d-flex justify-start">
               <v-card color="bg" class="navIcon">
                  <v-icon medium color="white" class="my-5 mx-6" @click="changeCompSize('large')"> mdi-application-array-outline</v-icon>
               </v-card>
               <v-card class="pa-5 d-flex" width="100%">
                  <span class="font-weight-bold mx-5 d-inline-block text-truncate" style="color:#fff;" v-if="curSystem">{{ curSystem.title }}</span>
                  <v-icon color="white" small class="mx-5 pb-1" @click="changeCompSize('medium')"> mdi-image-size-select-small mdi-rotate-180</v-icon>
                  <v-icon color="white" small class="mx-5 mb-1" @click="changeCompSize('small')"> mdi-chevron-right</v-icon>
                  <v-spacer></v-spacer>
                  <v-icon color="white" @click="closeViewer"> mdi-close-circle-outline</v-icon>
               </v-card>
            </v-card>
         </v-card>
         <v-card class="panzoomContainer" @mouseleave="pz_die" :class="[{ curosrAdd: cursor_addMode }]" ref="panzoomContainer" :width="panzoomContainerSize.width" :height="panzoomContainerSize.height">
            <panZoom ref="_panzoom" v-if="containerSize" :options="pz_options" @init="pz_onPanZoomInit" :class="['image-viewer',{ cursorMove: pz_cursorMove}]" :width="panzoomContainerSize.width" :height="panzoomContainerSize.height">
               <v-card ref="efi" class="flizi" :min-width="containerSize.width" :min-height="containerSize.height" :width="containerSize.width" :height="containerSize.height">
                  <v-img ref="_img" :min-width="containerSize.width" :min-height="containerSize.height" :src="`${curSystem.file_svg}?token=${authToken}`" :width="containerSize.width" :height="containerSize.height" @load="st_loaded" />
                  <v-stage
                     id="bigStage"
                     class= "drawstage"
                     ref= "st_stage"
                     :config= "containerSize" :min-width="containerSize.width" :min-height="containerSize.height" :width="containerSize.width" :height="containerSize.height">
                     <v-layer>
                        <v-rect v-if="!!newSystemTag" :config="newSystemTagConfig"></v-rect>
                        <v-rect v-for="item in tagItems" :key="`rectItem${item.id}`" :config="item"></v-rect>
                     </v-layer>
                  </v-stage>
               </v-card>
            </panZoom>
            <div class="panZoomFloatingSlider" @mouseenter="sliderOpacity=1" @mouseleave="sliderOpacity=0.2" :style="{ 'opacity': sliderOpacity }">
               <v-slider
                  max="10"
                  step="0.1"
                  min="0.3"
                  v-model="zoomSlider.value"
                  :color="zoomSlider.color"
                  :track-color="zoomSlider.trackColor"
                  :label="zoomSlider.label"
                  :thumb-color="zoomSlider.color"
                  @change="pz_doZoom($event)"
                  thumb-label=""
                  vertical
                  :disabled="pz_disableSlider"></v-slider>
               <v-icon size="24" color="primary" :disabled="pz_disableSlider" @click="pz_resetPanZoom" class="pl-2 ml-1" >mdi-stretch-to-page-outline</v-icon>
            </div>
         </v-card>
      </div>
   </v-scroll-x-reverse-transition>
</template>

<script>
/**
 * models
 */

/**
 * services
 */
import SystemTagService from '@/service/SystemTagService';

/**
 * components
 */

/**
 * helpers aka mixins
 */
import SystemPanzoomHelper from '@/components/Viewers/helpers/SystemPanzoomHelper.js'; // all elements starting with pz_ can be found in this file
import SystemStageHelper from '@/components/Viewers/helpers/SystemStageHelper';
import Util from '@/utils';

export default {
   name: 'ViewerSystem',
   mixins: [SystemPanzoomHelper, SystemStageHelper],
   data() {
      return {
         sliderOpacity: 0.2,
         systemTagService: new SystemTagService(),
         zoomSlider: { thumbLabel: 'zoom', val: 1, color: 'bgdark', trackColor: 'primary', thumbColor: 'primary' },
         curSystem: null,
         disableButtons: true,
         containerSize: { width: 0, height: 0 },
         disable_pointer_event: false,
         isDrawing: false,
         imgElement: null,
         cursor_addMode: false,
         newSystemTag: null,
         pz_cursorMove: false,
         tagItems: [],
         authToken: localStorage.getItem('token'),
      };
   },
   computed: {
      currCompSize() {
         return this.$store.getters.systemView_currectCompSize;
      },
      newSystemTagConfig() {
         const out = {
            id: 'newSystemTag',
            draggable: true,
            visible: true,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            fill: 'rgba(0,255,0,0.1)',
            stroke: 'green',
            listening: true,
            strokeWidth: 0.9,
         };
         if (this.newSystemTag) {
            out.x = this.newSystemTag.position.x;
            out.y = this.newSystemTag.position.y;
            out.width = this.newSystemTag.position.width;
            out.height = this.newSystemTag.position.height;
         }
         return out;
      },
      currentSystem() {
         return this.$store.getters.currentSystem;
      },
      compSize() {
         switch (this.currCompSize) {
         case 'small':
            return 'smallComp';
         case 'medium':
            return 'mediumComp';
         case 'large':
            return 'largeComp';
         default:
            return 'largeComp';
         }
      },
      currentTag() {
         return this.$store.getters.systemView_currentSystemTag;
      },
      currentAction() {
         return this.$store.getters.systemView_currentSystemTagAction;
      },
      isAddMode() {
         return !!((this.currentTag) && (this.currentAction === 'add'));
      },
      tags() {
         return this.$store.getters.listTags;
      },
      is_popup_visible() {
         return ((this.$store.getters.tags_isPopupVisible) && (this.$store.getters.tags_popupTagData));
      },
      viewableTags() {
         return this.$store.getters.tags_viewableTags;
      },
   },
   watch: {
      viewableTags: {
         handler: function (val) {
            this.updateTheTags(val);
         },
         deep: true,
      },
      currCompSize(val) {
         setTimeout(() => {
            this.pz_updatepanZoomContainerSize();
         }, 500);
      },
      is_popup_visible(val) {
         if (val) {
            // this.changeCompSize('large');
            setTimeout(() => {
               this.pz_updatepanZoomContainerSize();
            }, 500);
         }
      },
      isAddMode: {
         handler: function (val) {
            if (val) {
               this.cursor_addMode = true;
               return;
            }
            this.cursor_addMode = false;
         },
         immediate: true,
      },
      currentSystem(val) {
         this.curSystem = val;
         // this.pz_resetPanZoom();
      },
      tags: {
         handler: function (val) {
            this.tagItems = [];
            if (typeof val !== 'object' || val === null) {
               return;
            }
            this.updateTheTags(val);
         },
         immediate: true,
         deep: true,
      },
   },
   mounted() {
      this.curSystem = this.currentSystem;
   },
   created() {
   },
   methods: {
      updateTheTags(val) {
         val.forEach((tag) => {
            // if ((tag.position) && (this.viewableTags.includes(tag.id))){ <-- this is to show/hide feature (eye)
            if (tag.position) {
               const position = JSON.parse(tag.position);
               const fillColor = tag.tag.category.color.substr(0, 7);
               this.tagItems.push({
                  id: `rectid_${tag.id}`,
                  idNum: tag.id,
                  draggable: false,
                  visible: true,
                  x: position.x,
                  y: position.y,
                  width: position.width,
                  height: position.height,
                  fill: `${fillColor}2F`,
                  stroke: (tag.tag.title.includes('*')) ? '#FF0035' : fillColor,
                  listening: true,
                  shadowForStrokeEnabled: true,
                  strokeWidth: (tag.tag.title.includes('*')) ? 2 : 0.9,
                  onclick: (e) => {
                     if (e.evt.button === 2) { return; }
                     const tid = e.target.attrs.idNum;
                     const tagObj = this.$store.getters.listTags.find((o) => o.id === tid);
                     //this.$mixpanel.track('system-viewer-tag-clicked', { tag: tagObj.tag.name, system: this.currentSystem.name, category: tagObj.tag.category.name, id: tagObj.id ,systemId: this.currentSystem.id});
                     Util.sendEvent('System viewer','Tag clicked', tagObj.tag.name, {system: this.currentSystem.name, category: tagObj.tag.category.name, id: tagObj.id, systemId: this.currentSystem.id });
                     this.$store.dispatch('tags_setPopupTagDataAndShow', tagObj.tag);
                     const moveToCords = {
                        x: e.target.attrs.x,
                        y: e.target.attrs.y,
                        w: e.target.attrs.width,
                        h: e.target.attrs.height,
                     };
                     this.$store.dispatch('systemView_setPZMoveToTarget', moveToCords);
                     this.$store.dispatch('systemView_setCurrentSystemTag', tagObj.tag);
                  },
                  onmouseenter: () => {
                     document.getElementById('bigStage').style.cursor = 'pointer';
                  },
                  onmouseleave: () => {
                     document.getElementById('bigStage').style.cursor = 'default';
                  },
               });
            }
         });
      },
      changeCompSize(size) {
         //this.$mixpanel.track('system-viewer', { action: 'change-comp-size', size });
         Util.sendEvent('System viewer','Change comp size', {size});
         this.$store.dispatch('systemView_setCurrCompSize', size);
         if (!this.is_popup_visible) {
            return;
         }
         const pos = JSON.parse(this.$store.getters.tags_popupTagData.system_tag.position);
         const moveToCords = {
            x: pos.x,
            y: pos.y,
            w: pos.width,
            h: pos.height,
         };
         setTimeout(() => {
            this.$store.dispatch('systemView_setPZMoveToTarget', moveToCords);
         }, 500);
      },
      changeCompWin(size) {
         this.changeCompSize(size);
         // console.log('changeCompWin', size);
         // setTimeout(() => {
         //    this.pz_updatepanZoomContainerSize();
         // }, 100);
         // setTimeout(() => {
         //    this.pz_resetPanZoom();
         // }, 200);
      },
      closeViewer() {
         //this.$mixpanel.track('system-viewer', { action: 'close-viewer' });
         Util.sendEvent('System viewer','Close viewer');
         this.$store.dispatch('setCurrentSystem', null);
         this.$store.dispatch('tags_closePopup');
      },
      updateSystemTag() {
         Util.loading(true);
         this.systemTagService.update(this.newSystemTag.id, this.newSystemTag.toDto).then(() => {
            this.newSystemTag = null;
            this.$store.dispatch('systemView_setCurrentSystemTag', null);
            this.$store.dispatch('systemView_setCurrentSystemTagAction', null);
            this.$store.dispatch('updateTags');
            this.$store.dispatch('updateTagTree');
            Util.loading(false);
            Util.showMessage('updated sucessfuly!');
            this.$store.dispatch('tags_closePopup');
         });
      },
      hideTag() {
         /*
         const tagId = this.tagItems.findIndex(object => {
            console.log(object.id);
            return object.idNum === tid;
         });

          console.log('tagId', tid);
          this.tagItems[tagId].visible = false;
         */
      },
   },
};
</script>

<style scoped>
.panzoomContainer{
   width: 100%;
   height: 100%;
   overflow: hidden;
}
.systemViewContainer {
   position: fixed;
   right:0;
   background-color: #fff;
   overflow: hidden;
   transition:width 300ms cubic-bezier(0,.28,.65,.99), height 300ms cubic-bezier(0,.28,.65,.99);
   cursor: default;
}
.smallComp {
   width: 74px;
   height: 64px;
}
.mediumComp {
   width: 30%;
   height: calc(55vh - 68px);
}
.largeComp {
   width: calc(100vw - 377px);
   height: calc(100vh - 68px);
}
.btn {
   position: absolute;
   z-index: 1000;
}
.help {
   top:20px;
   left:20px;
}
.navIcon
{
   border-right: 5px var(--v-secondary-base) !important;
}
.panZoomFloatingSlider {
   background-color: #ffffffcc;
   border-radius: 5px;
   border: 1px solid #99999999;
   height:220px;
   position: absolute;
   top: 10px;
   left: 10px;
   width: 50px;
   transition: opacity 300ms cubic-bezier(0,.28,.65,.99);
}
.cursorMove{
   cursor: move;
}
.curosrAdd{
   cursor: crosshair;
}
.drawstage {
   position: absolute;
   top: 0;
   left: 0;
}
</style>
