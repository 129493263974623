/**
 * models
 */
import SystemTag from '@/models/SystemTag';

export default {
   computed: {
   },
   data() {
      return {
         st_stage_element: null,
         isDrawing: false,
      };
   },
   mounted() {

   },
   methods: {
      st_loaded(e) { // done loading the stage
         this.st_stage_element = this.$refs.st_stage.getNode();
         this.st_stage_element.on('mousedown', (e) => this.st_handleMouseDown(e));
         this.st_stage_element.on('mousemove', (e) => this.st_handleMouseMove(e));
         this.st_stage_element.on('mouseup', (e) => this.st_handleMouseUp(e));
         this.pz_updatepanZoomContainerSize(null);
      },
      st_handleMouseDown(e) {
         if (e.evt.button === 2) { this.pz_mouseDown(); return; }
         if (!this.isAddMode) {
            return;
         }
         this.newSystemTag = new SystemTag();
         this.newSystemTag.position.x = this.st_stage_element.getPointerPosition().x;
         this.newSystemTag.position.y = this.st_stage_element.getPointerPosition().y;
         this.isDrawing = true;
      },
      st_handleMouseMove() {
         if (!this.isDrawing) {
            return;
         }
         this.newSystemTag.position.width = this.st_stage_element.getPointerPosition().x - this.newSystemTag.position.x;
         this.newSystemTag.position.height = this.st_stage_element.getPointerPosition().y - this.newSystemTag.position.y;
      },
      st_handleMouseUp(e) {
         this.pz_mouseUp(e);
         if (!this.isDrawing) {
            return;
         }
         this.isDrawing = false;
         this.newSystemTag.position.width = this.st_stage_element.getPointerPosition().x - this.newSystemTag.position.x;
         this.newSystemTag.position.height = this.st_stage_element.getPointerPosition().y - this.newSystemTag.position.y;
         this.newSystemTag.system_id = this.currentSystem.id;
         this.newSystemTag.id = this.currentTag.system_tag_id;
         this.updateSystemTag();
      },

   },

};
