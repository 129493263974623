<template>
   <div class="sidelist pt-1">
      <v-card>
         <v-card-title class="pa-2 ">
            <v-icon class="closeBTN pl-0 pr-2" @click="closeListComp"> mdi-chevron-left </v-icon> Systems
            <!-- <v-icon class="ml-2 mr-2 toggleUpDown nobg" color="white" x-small :class='{ "rotate": itemsReverse }' @click="reverseList" :ripple="false"> mdi-arrow-down-bold </v-icon>--></v-icon>
         </v-card-title>
         <v-card class="pa-3">
            <v-icon color="white" @click="addItem" v-if="(auth.role!='viewer')"> mdi-plus-circle-outline </v-icon>
         </v-card>
      </v-card>
      <v-divider></v-divider>
      <v-container class="pa-2" >

         <v-card class="overflow-y-auto viewHeight pb-8">
            <v-sheet
               outlined
               color="#ffffff11"
               elevation="1"
               rounded
               width="275"
               v-for="item in items"
               :key="`${item.id}SystemList`"
               class="pt-1 pb-0 px-2 mb-3"

            >
               <v-card
                  class="mx-auto pa-0 ma-0"
                  max-width="344"
                  :ripple="false"
               >
                  <v-card-title class="pa-0 py-0 d-inline-block title_inner text-truncate"
                                style="max-width: 255px;" v-text="item.title"></v-card-title>
                  <v-img
                     :src="`${item.file_thumb}?token=${authToken}`"
                     class="white--text align-end pa-0 ma-0"
                     height="200px"
                     max-width="260px"
                     @click="changeSystem(item)"
                     rounded
                  ></v-img>
                  <v-card-actions class="pa-0 ma-0">
                     <v-btn
                        color="primary"
                        text
                        class="pa-0 ma-0"
                        @click="changeSystem(item)"
                     >
                        <v-icon
                           class="ma-0 pa-0"
                           left
                           small>
                           mdi-chevron-right
                        </v-icon>
                        Open
                     </v-btn>
                     <span class="px-1">&nbsp;</span>
                     <v-btn
                        color="primary"
                        text
                        class="pa-0 ma-0"
                        v-if="(auth.role!='viewer')"
                        @click="editItem(item)"
                     >
                        <v-icon
                           class="ma-0 pa-0"
                           left
                           x-small>
                           mdi-pencil-outline
                        </v-icon>
                        Edit
                     </v-btn>
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        text
                        class="pa-0 ma-0"
                        @click="toggleShortDesc(item)"
                        width="20"
                     >
                        <v-icon color="primary">{{ show[item.id] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                     </v-btn>
                  </v-card-actions >
                  <v-expand-transition >
                     <div v-show="show[item.id]">
                        <v-divider></v-divider>
                        <v-card-text class="white--text px-0 py-1 caption">
                           {{ item.short_desc || '' }}
                        </v-card-text>
                     </div>
                  </v-expand-transition>
               </v-card>

            </v-sheet>
         </v-card>
      </v-container>
      <v-card class="tagsBottomBar pa-0 ma-0">
         <v-toolbar
            dense
            rounded
            flat
            elevation="4"
            color="bgdark"
            class="ma-0 pa-0"
            dark
         >
            <v-toolbar-title >

            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn x-small class="pa-0 ma-1 text-uppercase toggleUpDown" color="white" @click="reverseList" :ripple="false" icon :class='{ "rotate": itemsReverse }'><v-icon x-small> mdi-arrow-up-bold </v-icon></v-btn>
         </v-toolbar>
      </v-card>
   </div>
</template>
<script>
import { auth } from '@/main';
import System from '@/models/System';
import Util from '@/utils';

export default {
   name: 'SystemList',
   data() {
      return {
         auth: auth,
         show: [],
         items: [],
         itemsReverse: false,
         authToken: localStorage.getItem('token'),
      };
   },
   computed: {
      listSystems() {
         return this.$store.getters.listSystems;
      },
   },
   watch: {
      listSystems(val) {
         this.updateShowCard(val);
         this.items = val;
      },

   },
   created() {
      this.updateShowCard(this.listSystems);
      this.items = this.listSystems;
      this.sortList(this.items);
   },
   methods: {
      sortList(val) {
         val = val.sort((a, b) => {
            if (a > b) { return 1; }
            if (a < b) { return -1; }
            // a must be equal to b
            return 0;
         });
         return val;
      },
      reverseList() {
         this.itemsReverse = !this.itemsReverse;
         this.items.reverse();
      },
      updateShowCard(val) {
         val.map((obj) => {
            this.$set(this.show, obj.id, false);
            return null;
         });
      },
      addItem() {
         const item = new System({ site_id: this.$store.getters.site.id });
         // this.$mixpanel.track('SystemList', { action: 'Add Item', item: item });
         Util.sendEvent('SystemList', 'Add Item', item.title, item);
         this.$store.dispatch('setSystemItemForm', item);
         this.changeSystem(null);
      },
      editItem(item) {
         console.log('edit', item);
         const obj = new System(item);
         // this.$mixpanel.track('SystemList', { action: 'Edit Item', item: obj });
         Util.sendEvent('SystemList', 'Edit Item', item.title, item);
         this.$store.dispatch('setSystemItemForm', obj);
         this.changeSystem(obj);
      },
      closeListComp() {
         // this.$mixpanel.track('SystemList', { action: 'Close List Comp' });
         Util.sendEvent('SystemList', 'Close List');
         this.$store.dispatch('setActiveListComp', this.name);
      },
      changeSystem(item) {
         this.closeTag();
         // this.$mixpanel.track('SystemList', { action: 'Change System' ,item });
         Util.sendEvent('SystemList', 'Change System', item.title, item);
         this.$store.dispatch('setCurrentSystem', item);
         this.$store.dispatch('systemView_setCurrCompSize', 'large');
      },
      toggleShortDesc(item) {
         // this.$mixpanel.track('SystemList', { action: 'Toggle Short Desc', id });
         Util.sendEvent('SystemList', 'Toggle Short Desc', item.title, item);
         this.$set(this.show, item.id, !this.show[item.id]);
      },
      closeTag() {
         // this.$mixpanel.track('SystemList', { action: 'Close Tag' });
         Util.sendEvent('SystemList', 'Close Tag');
         this.$store.dispatch('tags_closePopup');
      },
   },
};
</script>

<style lang="css" scoped>
   .viewHeight {
      height: calc(100vh - 180px) !important;
   }
   ::-webkit-scrollbar {
   width: 4px;
   height: 10px;
   margin-top: 0px;
 }
 ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
 }
 ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    border: 0px none #ffffff;
    border-radius: 1px;
    color:var(--v-bgoverlay-base)
 }
::-webkit-scrollbar-thumb:active {
   background: map-get($colors-alpha, 'bg-95');
 }
::-webkit-scrollbar-track {
   background: transparent;
   border: 0px none #ffffff;
   border-radius: 2px;
 }
 ::-webkit-scrollbar-track:hover {
   background: transparent;
 }
 ::-webkit-scrollbar-track:active {
   background: transparent;
 }
 ::-webkit-scrollbar-corner {
   background: transparent;
 }
 ::-webkit-scrollbar-track-piece:start {
     background: transparent;
     margin-top: 0px;
 }
 .v-card__actions{
    height: 30px;
 }
 .title_inner{
    height: 30px;
    font-size: 1rem !important;
 }
 .v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab)
 {
    min-width: 20px !important;
    height: 15px !important;
    padding: 0px !important;
 }
 .v-image{
   cursor: pointer !important;
 }
 .toggleUpDown {
    transition: transform .3s ease-in-out !important;
}

.toggleUpDown.rotate {
    transform: rotate(180deg);
}

.tagsBottomBar {
   position: absolute;
   bottom: 0px;
   width: 100%;
   height: auto;
   z-index: 1;
   border-top: 1px solid var(--v-bgoverlay-base);

}

.v-toolbar__content {
   padding: 0 !important;
   margin: 0 !important;
}

</style>
