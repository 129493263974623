<template>
   <div class="iframeContainer">
      <iframe allow="xr-spatial-tracking" src="blank.html" scrolling="no" frameborder="0" allowtransparency="false" class="iframeclass" name="potreeFrame" id="potreeFrameID"></iframe>
      <div v-if="showHint" @click="navHint" class="hintLayer">
         <v-img :src="require('../../assets/navigation.png')" class="my-3" contain width="100%" height="100%"/>
      </div>
      <div class="buttonsContainer">
         <v-tooltip open-on-hover open-delay="500" top>
            <template v-slot:activator="{ on, attrs }">
               <v-btn class="btn help mr-2" :color="showHint ? 'grey' : 'primary'" elevation="2" @click="navHint" v-bind="attrs" v-on="on" >
                  <v-icon>mdi-help</v-icon>
               </v-btn>
            </template>
            <span>Navigation Help</span>
         </v-tooltip>
         <v-tooltip open-on-hover open-delay="500" top>
            <template v-slot:activator="{ on, attrs }">
               <v-btn class="btn measure mr-2" color="primary" elevation="2" @click="measure" v-bind="attrs" v-on="on">
                  <v-icon>mdi-vector-line</v-icon>

               </v-btn>
            </template>
            <span>Measure</span>
         </v-tooltip>
         <v-tooltip open-on-hover open-delay="500" top v-if="removeMeasureBtn">
            <template v-slot:activator="{ on, attrs }">
               <v-btn class="btn measure mr-2"  color="primary" elevation="2" @click="removeMeasure" v-bind="attrs" v-on="on">
                  <v-icon>mdi-vector-polyline-minus </v-icon>
               </v-btn>
            </template>
            <span>Remove Measures</span>
         </v-tooltip>
         <v-tooltip open-on-hover open-delay="500" top>
            <template v-slot:activator="{ on, attrs }">
               <v-btn class="btn mr-2" :color="(show360)?'primary':'bg'" elevation="2" @click="imagesVisibility"  v-bind="attrs" v-on="on">
                  <v-icon :color="(show360)?'white':'primary'" >mdi-panorama-sphere-outline</v-icon>
               </v-btn>
            </template>
            <span v-if="show360"> Hide 360 </span>
            <span v-if="!show360"> Show 360 </span>
         </v-tooltip>
         <v-tooltip open-on-hover open-delay="500" top>
            <template v-slot:activator="{ on, attrs }">
               <v-btn class="btn mr-2"  color="primary" elevation="2" @click="clipVolume"  v-bind="attrs" v-on="on">
                  <v-icon >mdi-cube-outline</v-icon>
               </v-btn>
            </template>
            <span>Clip Volume</span>
         </v-tooltip>
         <v-snackbar
            color="primary"
            v-model="snackbar"
            :timeout="timeout"
            class="abitup"
         >
            Select two points in order to measure distance
         </v-snackbar>
         <!--<v-btn class="btn mr-2" :color="(show360)?'primary':'bg'" elevation="2" @click="imagesVisibility">
            <v-icon :color="(show360)?'white':'primary'">mdi-map-outline</v-icon>
         </v-btn>-->

      </div>
      <form method="post" action="" target="potreeFrame" id="potForm" name="potForm">
         <input type="hidden" name="token" :value="token" />
      </form>
      <!-- add a tooltip with tooltip data -->
      <v-tooltip v-model="toolTip.show"  top>
         <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="tooltipContainer">
               <div class="tooltipTitle">{{toolTip.text}}</div>
            </div>
         </template>
         <span>{{toolTip.text}}</span>
      </v-tooltip>
   </div>
</template>
<script>
/**
 * models
 */
// import Pointcloud from '@/models/Pointcloud';

/**
 * services
 */
import PointCloudTagService from '@/service/PointCloudTagService';

/**
 * components
 */

/**
 * helpers
 */
import Util from '@/utils';

export default {
   name: 'ViewerPointCloud',
   computed: {
      currentPointCloud() {
         return this.$store.getters.currentPointCloud;
      },
      currentTag() {
         return this.$store.getters.pointcloudView_currentPointcloudTag;
      },
      currentAction() {
         return this.$store.getters.pointcloudView_currentPointcloudAction;
      },
      pointCloudTagList() {
         return this.$store.getters.pointcloudView_currentPointcloudTagList;
      },
      listTags() {
         return this.$store.getters.getTagTree;
      },
      viewableTags() {
         return this.$store.getters.tags_viewableTags;
      },
      token() {
         return this.$store.getters.token;
      },
   },
   watch: {
      currentPointCloud: {
         handler(val) {
            if (typeof (val) === 'object') {
               this.openViewer(val.url);
            } else {
               this.pointCloudURL = 'blank.html';
            }
         },
         immediate: false,
      },
      currentAction(val) {
         this.actionHandler(val);
      },
      listTags() {
         this.getPointCloudTagsList();
      },
      viewableTags() {
         // this.getPointCloudTagsList();
         this.drawTags();
      },
   },
   data() {
      return {
         pointCloudTagService: new PointCloudTagService(),
         removeMeasureBtn: false,
         snackbar: false,
         timeout: 2000,
         pointCloudURL: 'blank.html',
         showHint: false,
         show360: true,
         formUrl: '',
         toolTip: {
            show: false,
            x: 0,
            y: 0,
            text: '',
         },
      };
   },
   mounted() {
      if (typeof (this.currentPointCloud) === 'object') {
         if (typeof (this.currentPointCloud.url) === 'string') {
            this.openViewer(this.currentPointCloud.url);
         }
      }
   },
   created() {
      window.addEventListener('message', this.receiveMessage);
   },

   methods: {
      openViewer(pcl_url) {
         let url = '';
         if (process.env.VUE_APP_IS_DEBUG === '1') {
            url = `${window.location.protocol}//${window.location.hostname}:8071/index.php?pcl_url=${pcl_url}`;
         } else {
            url = `/metaviewer/index.php?pcl_url=${pcl_url}`;
         }
         this.formUrl = url;
         document.forms.potForm.action = url;
         document.forms.potForm.submit();
      },
      receiveMessage(event) {
         if (typeof (event.data.type) === 'string') {
            // TODO: #3 need to check event.origin for security
            switch (event.data.type) {
            case 'createTag':
               event.preventDefault();
               this.addPointcloudTagDataToTag(event.data.data);
               break;
            case 'clickTag':
               event.preventDefault();
               this.passClickTag(event.data.data.id);
               break;
            case 'ready':
               event.preventDefault();
               this.getPointCloudTagsList();
               break;
            case 'toolTip':
               event.preventDefault();
               console.log('toolTip', event.data.data);
               this.toolTip.show =event.data.data.show;
               this.toolTip.x = event.data.data.x;
               this.toolTip.y = event.data.data.y;
               this.toolTip.text = event.data.data.text;
               
               break;
            default:
               break;
            }
         }
      },
      passClickTag(tagid) {
         const result = this.pointCloudTagList.find((obj) => obj.id === tagid);
         this.$store.dispatch('tags_setPopupTagDataAndShow', result.tag);
         // this.$store.commit('pointcloudView_setCurrentPointcloudTag', result);
      },
      clearTags() {
         window.potreeFrame.postMessage({ type: 'deleteAllTags' }, '*');
      },
      drawTags() {
         const tagsData = [];
         this.clearTags();
         this.pointCloudTagList.forEach((pc_tag) => {
            if (this.viewableTags.includes(pc_tag.tag.id)) {
               // window.potreeFrame.postMessage({ type: 'drawTag', data: pc_tag }, '*');
               tagsData.push(pc_tag);
            }
         });
         window.potreeFrame.postMessage({ type: 'drawTags', data: tagsData }, '*');
         this.$store.dispatch('pointcloudView_ProcessQueue');
      },
      getPointCloudTagsList() {
         if (this.currentPointCloud.id) {
            this.pointCloudTagService.list(this.currentPointCloud.id).then((res) => {
               this.$store.dispatch('pointcloudView_setCurrentPointcloudTagList', res.data);
               this.drawTags();
            });
         }
      },
      addPointcloudTagDataToTag(data) {
         const tmp_icon_pos = JSON.parse(data.icon_pos);
         const tmp_cam_pos = JSON.parse(data.cam_pos);

         Util.loading(true);
         const icon_pos = [tmp_icon_pos[0], tmp_icon_pos[1], tmp_icon_pos[2]];
         const cam_pos = [tmp_cam_pos[0], tmp_cam_pos[1], tmp_cam_pos[2]];
         const dts = {
            point_cloud_id: this.currentPointCloud.id,
            icon_pos: JSON.stringify(icon_pos),
            cam_pos: JSON.stringify(cam_pos),
            tag_id: this.currentTag.id,
         };
         if (data.id) {
            dts.id = data.id;
         }
         this.pointCloudTagService.updateOrCreate(dts).then(() => {
            this.newSystemTag = null;
            this.$store.dispatch('pointcloudView_setCurrentPointcloudTag', { tag: {}, action: false });
            this.$store.dispatch('updateTags');
            this.$store.dispatch('updateTagTree');
            Util.loading(false);
            Util.showMessage('updated sucessfuly!');
            this.$store.dispatch('tags_closePopup');
            this.getPointCloudTagsList();
         });
      },
      actionHandler(action) {
         switch (action) {
         case 'add':
            window.potreeFrame.postMessage({ type: 'putTag' }, '*');
            break;
         case 'view':
            window.potreeFrame.postMessage({ type: 'jumToTagId', data: { point_clould_tag_id: this.currentTag.point_cloud_tag_id } }, '*');
            this.$store.dispatch('pointcloudView_setCurrentPointcloudTag', { tag: {}, action: false });
            break;
         default:
            break;
         }
      },
      measure() {
         // this.$mixpanel.track('measure', { 'pointcloud': this.currentPointCloud.name, 'pointcloudId': this.currentPointCloud.id });
         Util.sendEvent('Point Cloud', 'measure', this.currentPointCloud.name, this.currentPointCloud.id);
         this.snackbar = true;
         this.removeMeasureBtn = true;
         window.potreeFrame.postMessage({ type: 'measure' }, '*');
      },
      removeMeasure() {
         // this.$mixpanel.track('removeMeasure', { 'pointcloud': this.currentPointCloud.name, 'pointcloudId': this.currentPointCloud.id });
         Util.sendEvent('Point Cloud', 'removeMeasure', this.currentPointCloud.name, this.currentPointCloud.id);
         this.removeMeasureBtn = false;
         window.potreeFrame.postMessage({ type: 'remove_measurement' }, '*');
      },
      imagesVisibility() {
         // this.$mixpanel.track('imagesVisibility '+this.show360, { 'pointcloud': this.currentPointCloud.name, 'pointcloudId': this.currentPointCloud.id });
         Util.sendEvent('Point Cloud', `imagesVisibility ${this.show360}`, this.currentPointCloud.name, this.currentPointCloud.id);
         this.show360 = !this.show360;
         window.potreeFrame.postMessage({ type: 'imagesVisibility', data: { visible: this.show360 } }, '*');
      },
      close360() {
         // this.$mixpanel.track('close360', { 'pointcloud': this.currentPointCloud.name, 'pointcloudId': this.currentPointCloud.id });
         Util.sendEvent('Point Cloud', 'Close 360', this.currentPointCloud.name, this.currentPointCloud.id);
         window.postMessage({ type: 'close360' }, '*');
      },
      clipVolume() {
         // this.$mixpanel.track('clipVolume', { 'pointcloud': this.currentPointCloud.name, 'pointcloudId': this.currentPointCloud.id });
         Util.sendEvent('Point Cloud', 'Clip Volume', this.currentPointCloud.name, this.currentPointCloud.id);
         window.potreeFrame.postMessage({ type: 'volume' }, '*');
      },
      navHint() {
         // this.$mixpanel.track('navigation hint '+!this.showHint, { 'pointcloud': this.currentPointCloud.name, 'pointcloudId': this.currentPointCloud.id });
         Util.sendEvent('Point Cloud', `Navigation Hint ${!this.showHint}`, this.currentPointCloud.name, this.currentPointCloud.id);
         this.showHint = !this.showHint;
      },

   },
   beforeDestroy: function () {
      window.removeEventListener('message', this.receiveMessage);
   },
};
</script>
<style lang="sass" scoped>
.v-tooltip__content
   border: 1px solid rgba(255,255,255,0.2) !important
   background-color: #002244
</style>
<style scoped>
.buttonsContainer
{
   position: absolute;
   bottom: 5px;
   right:0;
   left:0;
   margin: 0 auto;
   z-index: 1;
   width: max-content;
}

.btn {
   z-index: 1000;
   width: 25px !important;
   height: 25px !important;
   min-width: 25px !important;
   min-height: 25px !important;
}
.help {

}
.measure {

}
.iframeContainer {
   position: absolute;
   width: 100%;
   height: calc(100vh - 68px);
}
.iframeclass {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1;
}
.hintLayer {
   background-color: #000000a1;
   width: 100%;
   height: 100%;
   display: block;
   top: 0;
   left: 0;
   position: absolute;
   z-index: 2;
}
.abitup
{
   position: absolute;
   bottom: 50px;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1;
}
</style>
