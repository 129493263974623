var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iframeContainer"},[_c('iframe',{staticClass:"iframeclass",attrs:{"allow":"xr-spatial-tracking","src":"blank.html","scrolling":"no","frameborder":"0","allowtransparency":"false","name":"potreeFrame","id":"potreeFrameID"}}),(_vm.showHint)?_c('div',{staticClass:"hintLayer",on:{"click":_vm.navHint}},[_c('v-img',{staticClass:"my-3",attrs:{"src":require('../../assets/navigation.png'),"contain":"","width":"100%","height":"100%"}})],1):_vm._e(),_c('div',{staticClass:"buttonsContainer"},[_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn help mr-2",attrs:{"color":_vm.showHint ? 'grey' : 'primary',"elevation":"2"},on:{"click":_vm.navHint}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}])},[_c('span',[_vm._v("Navigation Help")])]),_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn measure mr-2",attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.measure}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vector-line")])],1)]}}])},[_c('span',[_vm._v("Measure")])]),(_vm.removeMeasureBtn)?_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn measure mr-2",attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.removeMeasure}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vector-polyline-minus ")])],1)]}}],null,false,2702461814)},[_c('span',[_vm._v("Remove Measures")])]):_vm._e(),_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn mr-2",attrs:{"color":(_vm.show360)?'primary':'bg',"elevation":"2"},on:{"click":_vm.imagesVisibility}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":(_vm.show360)?'white':'primary'}},[_vm._v("mdi-panorama-sphere-outline")])],1)]}}])},[(_vm.show360)?_c('span',[_vm._v(" Hide 360 ")]):_vm._e(),(!_vm.show360)?_c('span',[_vm._v(" Show 360 ")]):_vm._e()]),_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn mr-2",attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.clipVolume}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cube-outline")])],1)]}}])},[_c('span',[_vm._v("Clip Volume")])]),_c('v-snackbar',{staticClass:"abitup",attrs:{"color":"primary","timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Select two points in order to measure distance ")])],1),_c('form',{attrs:{"method":"post","action":"","target":"potreeFrame","id":"potForm","name":"potForm"}},[_c('input',{attrs:{"type":"hidden","name":"token"},domProps:{"value":_vm.token}})]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltipContainer"},'div',attrs,false),on),[_c('div',{staticClass:"tooltipTitle"},[_vm._v(_vm._s(_vm.toolTip.text))])])]}}]),model:{value:(_vm.toolTip.show),callback:function ($$v) {_vm.$set(_vm.toolTip, "show", $$v)},expression:"toolTip.show"}},[_c('span',[_vm._v(_vm._s(_vm.toolTip.text))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }