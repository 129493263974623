<template>
   <div class="sidelist pt-1">
      <v-card>
         <v-card-title class="pa-2 ">
            <v-icon class="closeBTN pl-0 pr-2" @click="closeListComp"> mdi-chevron-left </v-icon> Models
         </v-card-title>
      </v-card>
      <v-divider></v-divider>
      <v-container class="pa-2 " >
         <v-card class="overflow-y-auto viewHeight pb-8" >
            <template v-for="item in items">
               <v-card v-if="item.title" class="mb-3 mr-2" :key="`${item.id}ModelsList`" light rounded elevation="0" @click="changeModel(item)">
                  <v-img
                     src=""
                     class="white--text align-end"
                     gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                     height="100px"
                  >
                     <v-card-title v-text="item.title"></v-card-title>
                     <v-card-text class="text-caption mt-1">
                        {{ item.short_desc || '' }}
                     </v-card-text>
                  </v-img>
               </v-card>
            </template>
         </v-card >
      </v-container>
   </div>
</template>
<script>
import Util from '@/utils';

export default {
   name: 'PointCloudList',
   data() {
      return {
         items: [],
      };
   },
   computed: {
      listPointClouds() {
         return this.$store.getters.listPointClouds;
      },
   },
   watch: {
      listPointClouds(val) {
         this.items = val;
      },
   },
   created() {
      this.items = this.listPointClouds;
   },
   methods: {
      closeListComp() {
         //this.$mixpanel.track('Point cloud list', { action: 'Close List Comp' });
         Util.sendEvent('PointCloudList', 'Close');
         this.$store.dispatch('setActiveListComp', this.name);
      },
      changeModel(item) {
         //this.$mixpanel.track('Point cloud list', { action: 'Change model', model: item });
         Util.sendEvent('PointCloudList', 'Change model');
         this.$store.dispatch('setCurrentPointCloud', item);
      },
   },
};
</script>

<style lang="css" scoped>
   .viewHeight {
      height: calc(100vh - 100px) !important;
   }
   ::-webkit-scrollbar {
   width: 4px;
   height: 10px;
   margin-top: 0px;
 }
 ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
 }
 ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    border: 0px none #ffffff;
    border-radius: 1px;
    color:var(--v-bgoverlay-base)
 }
::-webkit-scrollbar-thumb:active {
   background: map-get($colors-alpha, 'bg-95');
}

::-webkit-scrollbar-track {
   background: transparent;
   border: 0px none #ffffff;
   border-radius: 2px;
 }
 ::-webkit-scrollbar-track:hover {
   background: transparent;
 }
 ::-webkit-scrollbar-track:active {
   background: transparent;
 }
 ::-webkit-scrollbar-corner {
   background: transparent;
 }
 ::-webkit-scrollbar-track-piece:start {
     background: transparent;
     margin-top: 0px;
 }
</style>
