import ParentModel from '@/models/parent/ParentModel.js';

export default class Pointcloud extends ParentModel {
   constructor(data) {
      super(data);
      if (data != null) {
         this.company_id = data.company_id;
         this.site_id = data.site_id;
         this.title = data.title;
         this.short_desc = data.short_desc;
         this.url = data.url;
      } else {
         this.company_id = '';
         this.site_id = '';
         this.title = '';
         this.short_desc = '';
         this.url = '';
      }
   }

   get toDto() {
      let dto = {};
      dto.company_id = this.company_id;
      dto.site_id = this.site_id;
      dto.title = this.title;
      dto.short_desc = this.short_desc;
      dto.url = this.url;
      return dto;
   }
}
