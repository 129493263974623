<template>
   <div class="sidelist pt-1">
      <v-card>
         <v-card-title class="pa-2 ">
            <v-tooltip open-on-hover open-delay="500" bottom >
               <template v-slot:activator="{ on, attrs }">
                  <v-icon class="closeBTN pl-0 pr-2" @click="closeListComp" v-bind="attrs" v-on="on"> mdi-chevron-left </v-icon> Tags
               </template>
               <span>Close tags list</span>
            </v-tooltip>
         </v-card-title>
         <v-card class="pa-3">
            <v-tooltip open-on-hover open-delay="500" bottom >
               <template v-slot:activator="{ on, attrs }">
                  <v-icon color="white" @click="addItem" v-if="(auth.role!='viewer')" v-show="!!currentSystem.id" v-bind="attrs" v-on="on"> mdi-plus-circle-outline </v-icon>
               </template>
               <span>Add a Tag</span>
            </v-tooltip>
         </v-card>
      </v-card>
      <v-divider></v-divider>
      <v-card class="pa-2">
         <v-text-field
            v-model="search"
            class="searchField pa-0 ma-0"
            label="Search a tag"
            solo
            hide-details
            clearable
            dense
            clear-icon="mdi-close-circle-outline"
            @input="filteredData"
         >
            <template v-slot:label class="searchSlot">
               <v-icon style="vertical-align: middle">
                  mdi-magnify
               </v-icon>
               Search for a tag
            </template>
         </v-text-field>

      </v-card>
      <v-container class="pa-0 pt-1" >
         <v-card class="overflow-y-auto viewHeight pb-8 tagSystem">
            <!--<v-card class="d-flex justify-end align-end flex-row px-1">

               <v-btn x-small class="mx-1 text-uppercase" color="bgdark" x-small  @click="reverseList" :ripple="false"><v-icon class="ma-0 pa-0 toggleUpDown" x-small :class='{ "rotate": itemsReverse }'> mdi-arrow-up-bold </v-icon></v-btn>
               <v-btn x-small class="mx-1 text-uppercase" color="bgdark" @click="setAllSystemsCategoriesToggle(false)">Hide all<v-icon right color="white" x-small>mdi-eye-off</v-icon></v-btn>
               <v-btn x-small class="mx-1 text-uppercase" color="bgdark" @click="setAllSystemsCategoriesToggle(true)">Show All<v-icon right color="white" x-small>mdi-eye</v-icon></v-btn>

            </v-card>-->
            <v-expansion-panels dense accordion class="transparentBG pa-0" :value="getSystemIndex">
               <v-expansion-panel class="pa-0 ma-0 transparentBG white--text" v-for="item in treeItemsSystemsFiltered" :key="`${item.id}SystemList`"  append-icon="" active-class="activeIcon activeGroupy">
                  <v-expansion-panel-header disable-icon-rotate class="pa-0 headerOnly maximizeWidth">
                     <template v-slot:actions>
                        <div class="maximizeWidth d-flex">
                           <div>
                              <v-icon color="white" class="sysExpandedIcon" :ripple="false">mdi-chevron-right</v-icon>
                           </div>
                           <v-card width="100%" class="d-flex justify-space-between maximizeWidth">
                              <div class="white--text">{{ item.title }}</div>
                           </v-card>
                           <div class="d-flex mx-2" @click="changeSystem(item.id)">
                           <v-tooltip open-on-hover open-delay="500" bottom >
                              <template v-slot:activator="{ on, attrs }">
                              <v-icon size="14" color="white" v-bind="attrs" v-on="on">mdi-application-import</v-icon>
                              </template>
                              <span>Change system</span>
                           </v-tooltip>
                           </div>
                        </div>
                     </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="ma-0 pa-0 pr-2" eager>
                     <v-list dense :class="['transparentBG', 'ma-0', 'pa-0', `group_of_systemid${item.id}`]">
                        <v-list-group eager dense :class="['listHeight', 'pa-0', 'ma-0', 'mr0']" v-for="(parentCat, parent_id) in catFilter(item.parent_cat)" :key="`${parent_id}parentCat`" :value="openParent==parent_id" :ref="`parentCat_${parent_id}`" append-icon="" active-class="tagActiveIcon " :disabled="true" @click="parentCatChanged(parent_id)">
                           <div slot="activator" class="pa-0 ma-0 d-flex justify-space-between maximizeWidth itemHeight cursorDefault" :ripple="false">
                              <div class="pa-0 ma-0 white--text text-truncate pt-1 level1">{{ parentCat.title}}</div>
                              <div>
                                 <v-icon :treeSystemId="item.id" :treeParenCat="parent_id" :class="['smlIcon', `parent${parent_id}`, 'parentEye', 'ma-0', 'pa-0', 'disabledEye']" @click="toggleCatView" >mdi-eye</v-icon>
                              </div>
                           </div>
                           <template v-slot:prependIcon :ripple="false">
                              <v-icon color="white" class="tagExpandedIcon pa-0 ma-0" @click="parentCatChanged(parent_id)" :ripple="false">mdi-chevron-right</v-icon>
                           </template>
                           <v-list-group eager dense sub-group class="pa-0 ma-0 mr0" v-for="(childCat, child_id) in catFilter(parentCat.child)" :key="`${child_id}chileCat`" :value="openChild==child_id" :ref="`childCat_${child_id}`" append-icon="" active-class="subTagActiveIcon" :ripple="false" @click="childCatChanged(child_id)" :disabled="true">
                              <div slot="activator" class="d-flex justify-space-between maximizeWidth itemHeight cursorDefault">
                                 <div class="white--text text-truncate pt-1 level2" :ripple="false">{{ childCat.title}}</div>
                                 <div>
                                    <v-icon :treeSystemId="item.id" :treeSubCat="child_id" :treeParenCat="parent_id" :class="['smlIcon', `subcat${child_id}`, `parent${parent_id}`, 'subcatEye', 'disabledEye']" @click="toggleSubcatView" :ref="`cat_id${child_id}`">mdi-eye</v-icon>
                                 </div>
                              </div>
                              <template v-slot:prependIcon>
                                 <v-icon color="white" class="subTagExpandedIcon" @click="childCatChanged(child_id)" :ripple="false">mdi-chevron-right</v-icon>
                              </template>
                              <v-list-item dense class="pa-0 ma-0 listHeight" v-for="(tag, tagId) in tagFilter(childCat.tags)" :key="`${tagId}`" :ref="`tag_${tagId}`" append-icon="">
                                 <v-list-item-content>
                                    <v-card class="d-flex justify-space-between mb-2 justify-end">
                                       <v-card width="100%" class="white--text text-truncate pl-6 level2 d-flex justify-space-between"  :ripple="false">
                                          <div @click="toggleTag(tag)">
                                             {{ tag.title }}
                                          </div>
                                          <v-card class="d-flex justify-space-between" width="55">
                                             <v-tooltip open-on-hover open-delay="500" bottom >
                                                <template v-slot:activator="{ on, attrs }">
                                                   <v-icon class="smlIcon" :ripple="false" :color="tagActionColor('pointCloudTag',tag)" @click="tagAction('pointCloudTag',tag)" v-bind="attrs" v-on="on">mdi-cube-scan</v-icon>
                                                </template>
                                                <span>Point cloud tag</span>
                                             </v-tooltip>
                                             <v-tooltip open-on-hover open-delay="500" bottom >
                                                <template v-slot:activator="{ on, attrs }">
                                                   <v-icon class="smlIcon" :ripple="false" :color="tagActionColor('systemTag',tag)" x-small @click="tagAction('systemTag',tag)" v-bind="attrs" v-on="on">mdi-application-array-outline</v-icon>
                                                </template>
                                                <span>System tag</span>
                                             </v-tooltip>
                                             <v-icon :treeTagID="tagId" :treeSystemId="item.id" :treeSubCat="child_id" :treeParenCat="parent_id" :class="['smlIcon',`tag${tagId}`, `subcat${child_id}`, `parent${parent_id}`, 'tagEye', 'disabledEye' ]" :ripple="false" @click="toggleTagView" :id="`fortagid${tagId}`">mdi-eye</v-icon>
                                          </v-card>
                                       </v-card>
                                    </v-card>
                                    <v-expand-transition>
                                       <v-card class="desc white--text text-caption level3 lighter pl-6" :ref="`tagDesc_${tagId}`" height="auto" v-show="tagShortDesc[tagId]">
                                          {{ tag.short_desc }}
                                       </v-card>
                                    </v-expand-transition>
                                 </v-list-item-content>
                              </v-list-item>
                           </v-list-group>
                        </v-list-group>
                     </v-list>
                  </v-expansion-panel-content>
               </v-expansion-panel>
            </v-expansion-panels>

         </v-card>
      </v-container>
      <v-card class="tagsBottomBar pa-0 ma-0">
         <v-toolbar
            dense
            rounded
            flat
            elevation="4"
            color="bgdark"
            class="ma-0 pa-0"
            dark
         >
            <v-toolbar-title >

            </v-toolbar-title>

            <v-spacer></v-spacer>
             <v-tooltip open-on-hover open-delay="500" top >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small class="pa-0 ma-1 text-uppercase toggleUpDown" color="white" @click="reverseList" :ripple="false" v-bind="attrs" v-on="on" icon :class='{ "rotate": itemsReverse }'><v-icon x-small> mdi-arrow-up-bold </v-icon></v-btn>
                  </template>
                  <span>Sort items</span>
               </v-tooltip>
               <v-tooltip open-on-hover open-delay="500" top >
                  <template v-slot:activator="{ on, attrs }">
            <v-btn x-small class="pa-0 ma-1 text-uppercase" color="white" @click="setAllSystemsCategoriesToggle(false)" v-bind="attrs" v-on="on" icon><v-icon x-small>mdi-eye-off</v-icon></v-btn>
            </template>
                  <span>Hide all items </span>
               </v-tooltip>
               <v-tooltip open-on-hover open-delay="500" top>
                  <template v-slot:activator="{ on, attrs }">
            <v-btn x-small class="pa-0 ma-1 text-uppercase" color="white" @click="setAllSystemsCategoriesToggle(true)" v-bind="attrs" v-on="on" icon><v-icon x-small>mdi-eye</v-icon></v-btn>
            </template>
                  <span>Show all items </span>
               </v-tooltip>
               
         </v-toolbar>
      </v-card>
   </div>
</template>

<script>
/**
 * models
 */
import { auth } from '@/main';
import Tag from '@/models/Tag';
import Util from '@/utils';

/**
 * Services
 */

export default {
   name: 'TagList',
   data() {
      return {
         auth: auth,
         items: [],
         treeItems: [],
         tagShortDesc: [],
         openParent: null,
         openChild: null,
         accordionSystemIndex: null,
         search: null,
         viewableCatetoriesID: [],
         viewableTagsID: [],
         viewableSystemsID: [],
         hasTagsVisible: false,
         toggle_exclusive: 2,
         itemsReverse: false,
      };
   },
   computed: {
      tagsVisible() {
         return document.querySelectorAll('.tagEye:not(.disabledEye)').length > 0;
      },
      tagFilter: function () {
         const VIDS = this.viewableTagsID;
         const searchKey = this.search;
         return (cats) => {
            if (searchKey) {
               const a = Object.keys(cats).filter((val) => {
                  const catID = parseInt(val);
                  return VIDS.includes(catID);
               }).reduce((obj, key) => {
                  obj[key] = cats[key];
                  return obj;
               }, {});
               // this.$mixpanel.track('TagList', { action:'tagFilter',search: searchKey, result: a });
               return a;
            }

            return cats;
         };
      },
      catFilter: function () {
         const VIDS = this.viewableCatetoriesID;
         const searchKey = this.search;
         return (cats) => {
            if (searchKey) {
               const a = Object.keys(cats).filter((val) => {
                  const catID = parseInt(val);
                  return VIDS.includes(catID);
               }).reduce((obj, key) => {
                  obj[key] = cats[key];
                  return obj;
               }, {});
               return a;
            }
            return cats;
         };
      },

      treeItemsSystemsFiltered() {
         if (this.search) {
            return this.treeItems.filter((item) => this.viewableSystemsID.includes(item.id));
         }
         return this.treeItems;
      },

      allTags() {
         return this.$store.getters.getAllTags;
      },
      site_id() {
         return this.$store.getters.site.id;
      },
      currentSystem() {
         return this.$store.getters.currentSystem || { id: 0 };
      },
      currentOpenParent() {
         return this.$store.getters.getTagsTree_active.parentCat;
      },
      currentOpenChild() {
         return this.$store.getters.getTagsTree_active.childCat;
      },
      tagTree() {
         return this.$store.getters.getTagTree;
      },
      currentSystemTag() {
         return this.$store.getters.systemView_currentSystemTag || { id: 0 };
      },
      currentSystemTagAction() {
         return this.$store.getters.systemView_currentSystemTagAction;
      },
      getSystemIndex() {
         return this.treeItems.findIndex((item) => item.id === this.currentSystem.id);
      },
      getTagToForceView() {
         return this.$store.getters.listForcedViewbleTag;
      },
   },
   watch: {
      tagsVisible: {
         handler(val) {
            console.log('tagsVisible', val);
            this.hasTagsVisible = val;
         },
         deep: true,
         immediate: true,
      },
      currentOpenParent(val) {
         this.openParent = val;
      },
      currentOpenChild(val) {
         this.openChild = val;
      },
      tagTree(val) {
         this.treeItems = val;
      },
      getTagToForceView(tag) {
         if (tag) {
            this.setTagViewOn(tag.system_tag.system_id, tag.category.parent_id, tag.category.id, tag.id);
            this.$store.dispatch('forceViewableTag', null);
            this.updateViewable();
         }
      },
   },
   created() {
      this.$store.dispatch('pointcloudView_setCurrentPointcloudTag', { tag: {}, action: false });
      this.treeItems = this.tagTree;
      this.sortList(this.treeItemsSystemsFiltered);
   },

   mounted() {
      this.openParent = this.currentOpenParent;
      this.openChild = this.currentOpenChild;
      this.changeSystem(this.currentSystem.id);
      clearInterval(window.stam);
      this.updateViewableTagsFromBefore();
   },
   destroyed() {
      this.$store.dispatch('updateTagsTreeChildCat', null);
      this.$store.dispatch('updateTagsTreeParentCat', null);
      this.$store.dispatch('systemView_setCurrentSystemTagAction', false);
      this.$store.dispatch('systemView_setCurrentSystemTag', {});
   },
   methods: {
      sortList(val) {
         val = val.sort((a, b) => {
            if (a > b) { return 1; }
            if (a < b) { return -1; }
            // a must be equal to b
            return 0;
         });
         return val;
      },
      reverseList() {
         this.itemsReverse = !this.itemsReverse;
         this.treeItemsSystemsFiltered.reverse();
      },
      updateViewableTagsFromBefore() {
         const tag_id_list = this.$store.getters.tags_viewableTags;
         let tagObj;
         tag_id_list.forEach((tag_id) => {
            tagObj = this.allTags.find((o) => o.id === tag_id);
            if (tagObj) {
               this.setTagViewOn(tagObj.system_tag.system_id, tagObj.category.parent_id, tagObj.category.id, tagObj.id);
            }
         });
         this.updateViewable();
      },
      toggleCatView(e) {
         const parentCatId = parseInt(e.target.getAttribute('treeParenCat'));
         const system_id = parseInt(e.target.getAttribute('treeSystemId'));
         if (!parentCatId) {
            return;
         }
         if (!e.target.classList.contains('disabledEye')) {
            // this.$mixpanel.track('TagList', { action: 'toggleCatView-disable', parentCatId, system_id });
            Util.sendEvent('Tag List', 'toggleCatView-disable', parentCatId, system_id);
            document.querySelectorAll(`.group_of_systemid${system_id} .parent${parentCatId}`).forEach((item) => { item.classList.remove('disabledEye'); item.classList.add('disabledEye'); });
            e.target.classList.remove('disabledEye');
            e.target.classList.add('disabledEye');
         } else {
            // this.$mixpanel.track('TagList', { action: 'toggleCatView-enable', parentCatId, system_id });
            Util.sendEvent('Tag List', 'toggleCatView-enable', parentCatId, system_id);
            document.querySelectorAll(`.group_of_systemid${system_id} .parent${parentCatId}`).forEach((item) => item.classList.remove('disabledEye'));
            e.target.classList.remove('disabledEye');
         }
         this.updateViewable();
      },
      toggleSubcatView(e) {
         const parentCatId = parseInt(e.target.getAttribute('treeParenCat'));
         const system_id = parseInt(e.target.getAttribute('treeSystemId'));
         const subCatId = parseInt(e.target.getAttribute('treeSubCat'));

         if (!subCatId) {
            return;
         }

         if (!e.target.classList.contains('disabledEye')) {
            // this.$mixpanel.track('TagList', { action: 'toggleSubcatView-disable', parentCatId, system_id, subCatId });
            Util.sendEvent('Tag List', 'toggleSubcatView-disable', parentCatId, system_id, subCatId);
            document.querySelectorAll(`.group_of_systemid${system_id} .subcat${subCatId}`).forEach((item) => { item.classList.remove('disabledEye'); item.classList.add('disabledEye'); });
            e.target.classList.add('disabledEye');
         } else {
            // this.$mixpanel.track('TagList', { action: 'toggleSubcatView-enable', parentCatId, system_id, subCatId });
            Util.sendEvent('Tag List', 'toggleSubcatView-enable', parentCatId, system_id, subCatId);
            document.querySelector(`.group_of_systemid${system_id} .parent${parentCatId}.parentEye`).classList.remove('disabledEye');
            document.querySelectorAll(`.group_of_systemid${system_id} .subcat${subCatId}`).forEach((item) => { item.classList.remove('disabledEye'); });
            e.target.classList.remove('disabledEye');
         }
         this.updateViewable();
      },
      toggleTagView(e) {
         if (!e.target.classList.contains('disabledEye')) {
            // this.$mixpanel.track('TagList', { action: 'toggleTagView-disable', tagId: e.target.getAttribute('treeTagId') });
            Util.sendEvent('Tag List', 'toggleTagView-disable', e.target.getAttribute('treeTagId'));
            e.target.classList.remove('disabledEye');
            e.target.classList.add('disabledEye');
         } else {
            // this.$mixpanel.track('TagList', { action: 'toggleTagView-enable', tagId: e.target.getAttribute('treeTagId') });
            Util.sendEvent('Tag List', 'toggleTagView-enable', e.target.getAttribute('treeTagId'));
            const subCat = e.target.getAttribute('treeSubCat');
            const parentCat = e.target.getAttribute('treeParenCat');
            const system_id = e.target.getAttribute('treeSystemId');
            const tag_id = e.target.getAttribute('treeTagID');
            this.setTagViewOn(system_id, parentCat, subCat, tag_id);
         }
         this.updateViewable();
      },
      setTagViewOn(system_id, parentCat, subCat, tag_id) {
         document.querySelector(`.group_of_systemid${system_id} .subcat${subCat}.subcatEye`).classList.remove('disabledEye');
         document.querySelector(`.group_of_systemid${system_id} .parent${parentCat}.parentEye`).classList.remove('disabledEye');
         document.querySelector(`#fortagid${tag_id}`).classList.remove('disabledEye');
      },
      setTagViewOff(system_id, parentCat, subCat, tag_id) {
         document.querySelector(`.group_of_systemid${system_id} .subcat${subCat}.subcatEye`).classList.remove('disabledEye');
         document.querySelector(`.group_of_systemid${system_id} .parent${parentCat}.parentEye`).classList.remove('disabledEye');
         document.querySelector(`#fortagid${tag_id}`).classList.remove('disabledEye');
         document.querySelector(`.group_of_systemid${system_id} .subcat${subCat}.subcatEye`).classList.add('disabledEye');
         document.querySelector(`.group_of_systemid${system_id} .parent${parentCat}.parentEye`).classList.add('disabledEye');
         document.querySelector(`#fortagid${tag_id}`).classList.add('disabledEye');
      },
      setAllSystemsCategoriesToggle(is_show = false) {
         let subCat;
         let parentCat;
         let system_id;
         let tag_id;
         Util.sendEvent('Tag List', is_show ? 'Show All Tags' : 'Hide All Tags');
         document.querySelectorAll('.tagEye').forEach((item) => {
            subCat = item.getAttribute('treeSubCat');
            parentCat = item.getAttribute('treeParenCat');
            system_id = item.getAttribute('treeSystemId');
            tag_id = item.getAttribute('treeTagID');
            if (is_show) {
               // this.$mixpanel.track('System View', { 'action': 'Show All Tags' });
               this.setTagViewOn(system_id, parentCat, subCat, tag_id);
            } else {
               // this.$mixpanel.track('System View', { 'action': 'Hide All Tags' });
               this.setTagViewOff(system_id, parentCat, subCat, tag_id);
            }
         });
         this.updateViewable();
      },
      updateViewable() {
         let myregexp = /^(group_of_systemid)([0-9]+)$/i;
         let match;
         const system_id = this.currentSystem.id;
         if (!system_id) {
            console.log('system_id not found');
            // return;
         }
         this.$store.dispatch('tags_updateViewableTags', []);
         // const a = document.querySelectorAll(`.group_of_systemid${system_id} .tagEye:not(.disabledEye)`); // only for current system
         const a = document.querySelectorAll('.tagEye:not(.disabledEye)');
         const viewableTags = [];
         myregexp = /^(fortagid)([0-9]+)$/i;
         a.forEach((item) => {
            match = myregexp.exec(item.id);
            if (match != null) {
               viewableTags.push(parseInt(match[2]));
            }
         });
         this.$store.dispatch('tags_updateViewableTags', viewableTags);
      },
      filteredData() {
         this.viewableSystemsID = [];
         this.viewableTagsID = [];
         this.viewableCatetoriesID = [];
         if (this.search) {
            this.allTags.forEach((tag) => {
               if (tag.title.toLowerCase().includes(this.search.toLowerCase())) {
                  this.viewableSystemsID.push(tag.system_tag.system_id);
                  this.viewableCatetoriesID.push(tag.category_id);
                  this.viewableCatetoriesID.push(tag.category.parent_id);
                  this.viewableTagsID.push(tag.id);
               }
            });
            // this.updateViewable();
            // this.$mixpanel.track('Tag List', { action:'Tag Filter', search: this.search, result:{systems: this.viewableSystemsID, tags: this.viewableTagsID, categories: this.viewableCatetoriesID} });
            Util.sendEvent('Tag List', 'Tag Filter', this.search, { systems: this.viewableSystemsID, tags: this.viewableTagsID, categories: this.viewableCatetoriesID });
         }
      },

      findInArray(arr, term) {
         const matches = [];
         if (!Array.isArray(arr)) return matches;
         arr.forEach((i) => {
            if (i.value.includes(term)) {
               matches.push(i);
            } else {
               const childResults = this.findInArray(i.children, term);
               if (childResults.length) { matches.push({ ...i, children: childResults }); }
            }
         });
         return matches;
      },

      parentCatChanged(id) {
         this.$store.dispatch('updateTagsTreeChildCat', null);
         this.$store.dispatch('updateTagsTreeParentCat', id);
      },
      childCatChanged(id) {
         this.$store.dispatch('updateTagsTreeChildCat', id);
      },
      addItem() {
         // this.$mixpanel.track('TagList', { action: 'Add item' });
         Util.sendEvent('Tag List', 'Add item');
         const item = new Tag({ site_id: this.$store.getters.site.id });
         this.$store.dispatch('setTagItemForm', item);
      },
      closeListComp() {
         // this.$mixpanel.track('Tag List', { action:'Close' });
         Util.sendEvent('Tag List', 'Close');
         this.$store.dispatch('setActiveListComp', this.name);
      },

      changeSystem(system_id) {
         this.closeTag();
         const item = this.$store.getters.listSystems.find((o) => o.id === system_id);
         // this.$mixpanel.track('Tag List', { action:'Change System', system: item.name, system_id: item.id });
         Util.sendEvent('Tag List', 'Change System', item.title, item);
         setTimeout(() => {
            this.$store.dispatch('setCurrentSystem', item);
            this.$store.dispatch('systemView_setCurrCompSize', 'large');
         }, 500); // let the animation finish before changing the system otherwise graphical glitches may occur
         // this.updateViewable();
      },
      closeTag() {
         this.$store.dispatch('tags_closePopup');
      },
      toggleTag(tag) {
         // if system view is open, move to tag
         if (this.currentSystem) {
            // this.tagAction('systemTag', tag);
            this.toggleShow(tag.id);
         }
      },
      toggleShow(tag_id) {
         this.$set(this.tagShortDesc, tag_id, !this.tagShortDesc[tag_id]);
      },
      tagAction(type, tag) {
         const tagObj = this.allTags.find((o) => o.id === tag.id);
         switch (type) {
         case 'systemTag':
            this.changeSystem(tagObj.system_tag.system_id);
            setTimeout(() => {
               if (tagObj.system_tag.position) {
                  this.$store.dispatch('systemView_setCurrCompSize', 'large');
                  const pos = JSON.parse(tag.system_tag.position);
                  const moveToCords = {
                     x: pos.x,
                     y: pos.y,
                     w: pos.width,
                     h: pos.height,
                  };
                  setTimeout(() => {
                     // this.$mixpanel.track('Tag List', { action:'System View Move to Tag', tag: tagObj.title, tag_id: tagObj.id });
                     Util.sendEvent('Tag List', 'System View Move to Tag', tagObj.title, tagObj.id);
                     this.$store.dispatch('systemView_setPZMoveToTarget', moveToCords);
                     this.$store.dispatch('tags_setPopupTagDataAndShow', tagObj);
                     this.$store.dispatch('systemView_setCurrentSystemTagAction', 'view');
                  }, 500);
               } else {
                  // this.$mixpanel.track('Tag List', { action:'Tag Action', type: 'systemTag add position', tag: tagObj.title, tag_id: tagObj.id, system: tagObj.system_tag.system.name, system_id: tagObj.system_tag.system.id });
                  Util.sendEvent('Tag List', 'Tag Action', 'systemTag add position', tagObj.title, tagObj.id, tagObj.system_tag.system.name, tagObj.system_tag.system.id);
                  this.$store.dispatch('systemView_setCurrentSystemTagAction', 'add');
                  this.$store.dispatch('tags_setPopupVisible', false);
               }
               this.$store.dispatch('systemView_setCurrentSystemTag', tagObj);
            }, 500);
            break;
         case 'pointCloudTag':
            this.$store.dispatch('forceViewableTag', tagObj);
            this.$store.dispatch('systemView_setCurrCompSize', 'small');
            if (tagObj.point_cloud_tag_id) {
               // this.$store.dispatch('pointcloudView_setCurrentPointcloudTag', { tag: tagObj, action: 'view' });

               this.$store.dispatch('forceViewableTag', tagObj);
               setTimeout(() => {
                  // this.$mixpanel.track('Tag List', { action:'Point Cloud View Move to Tag', tag: tagObj.title, tag_id: tagObj.id });
                  Util.sendEvent('Tag List', 'Point Cloud View Move to Tag', tagObj.title, tagObj.id);
                  this.$store.dispatch('systemView_setCurrCompSize', 'small');
                  this.$store.dispatch('pointcloudView_setCurrentPointcloudTag', { tag: tagObj, action: 'view' });
               }, 500);
            } else {
               // this.$mixpanel.track('Tag List', { action:'Tag Action', type: 'pointCloudTag add position', tag: tagObj.title, tag_id: tagObj.id, system: tagObj.system_tag.system.name, system_id: tagObj.system_tag.system.id });
               Util.sendEvent('Tag List', 'Tag Action', 'pointCloudTag add position', tagObj.title, tagObj.id, tagObj.system_tag.system.name, tagObj.system_tag.system.id);
               this.$store.dispatch('pointcloudView_setCurrentPointcloudTag', { tag: tagObj, action: 'add' });
            }

            break;
         default:
            break;
         }
      },
      tagActionColor(type, tag) {
         switch (type) {
         case 'systemTag':
            if (this.currentSystemTag.id === tag.id) {
               if (this.currentSystemTagAction === 'add') {
                  return 'accent';
               }
               if (this.currentSystemTagAction === 'view') {
                  return 'white';
               }
            }
            return (tag.system_tag.position) ? 'white' : 'accent';
         case 'pointCloudTag':
            return (tag.point_cloud_tag) ? 'white' : 'accent';
         default:
            return 'white';
         }
      },
   },
};
</script>
<style lang="sass" scoped>
.v-tooltip__content
   border: 1px solid rgba(255,255,255,0.2) !important
   background-color: #002244
</style>
<style lang="css" scoped>
.transparentBG {
   background-color: transparent !important;
}
.debug {
   background-color: #ff0000;
}
   .viewHeight {
      height: calc(100vh - 200px) !important;
   }
   ::-webkit-scrollbar {
   width: 4px;
   height: 10px;
   margin-top: 0px;
 }
 ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
 }
 ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    border: 0px none #ffffff;
    border-radius: 1px;
    color:var(--v-bgoverlay-base)
 }
::-webkit-scrollbar-thumb:active {
   background: map-get(\$colors-alpha, 'bg-95');
}
::-webkit-scrollbar-track {
   background: transparent;
   border: 0px none #ffffff;
   border-radius: 2px;
 }
 ::-webkit-scrollbar-track:hover {
   background: transparent;
 }
 ::-webkit-scrollbar-track:active {
   background: transparent;
 }
 ::-webkit-scrollbar-corner {
   background: transparent;
 }
 ::-webkit-scrollbar-track-piece:start {
     background: transparent;
     margin-top: 0px;
 }
 .v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-expansion-panel-header{
   padding-top: 0.25rem !important;
   padding-bottom: 0.25rem !important;
   margin: 0.1rem;
   min-height: 25px !important;
   white-space: nowrap;
   font-weight: 600;
   font-size: 0.9rem;
   border-bottom: 1px solid var(--v-bgoverlay-base);
}

.v-list-item
{
   border-bottom: 1px solid var(--v-bgoverlay-base);
}

.v-expansion-panel::before{
   box-shadow: none;
}

.level1
{
   font-size: 0.8rem !important;

}
.level2
{
   font-size: 0.75rem !important;
}
.level3
{
   font-size: 0.65rem !important;
   display: -webkit-box;
   -webkit-line-clamp: 5;
   -webkit-box-orient: vertical;
   -webkit-mask-image: -webkit-gradient(linear, left 80%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))
}
.searchField
{
   border-radius: 0.25rem !important;
   font-size: 0.8rem;
}
.searchField:focus
{
   color: var(--v-bgdark-base) !important;
}

</style>
<style >

.cursorDefault {
   cursor: default !important;
}

.v-expansion-panel-header__icon {
   width: 100% !important;
   margin-left: 0px !important;
}

.tagExpandedIcon{
   transition: all 0.5s 0.1s;
   transform: rotate(0) !important;
}
.tagActiveIcon .tagExpandedIcon{
   transform: rotate(90deg) !important;
}

.subTagExpandedIcon{
   transition: all 0.5s 0.1s;
   transform: rotate(0) !important;
}

.subTagActiveIcon .subTagExpandedIcon{
   transform: rotate(90deg) !important;
}

.sysExpandedIcon{
   transition: all 0.5s 0.1s;
   transform: rotate(0) !important;
}
.activeGroupy .sysExpandedIcon{
   transform: rotate(90deg) !important;
}
.tagSystem .v-list-item {
   padding: 0 !important;
   padding-left: 5px !important;
}
.tagSystem .v-list-group--sub-group .v-list-item {
   padding-left: 10px !important;
}
.mr0 > .v-list-item div {
   margin-right: 0 !important;
   margin:0 !important;
}
.maximizeWidth {
   width: 100% !important;
}

.tagSystem .v-list-item
{
   min-height: 25px !important;
   padding-bottom: 0 !important;
   padding-top: 0 !important;
}

.smlIcon
{
   font-size: 14px !important;
   text-decoration: none !important;
}
.lighter
{
   font-weight: 300 !important;
}
.tagEye, .subcatEye, .parentEye {
   color:#fff !important;
}
.disabledEye {
   color: grey !important;
}
.toggleUpDown {
    transition: transform .3s ease-in-out !important;
}

.toggleUpDown.rotate {
    transform: rotate(180deg);
}

.tagsBottomBar {
   position: absolute;
   bottom: 0px;
   width: 100%;
   height: auto;
   z-index: 1;

}

.v-toolbar__content {
   padding: 0 !important;
   margin: 0 !important;
}
</style>
