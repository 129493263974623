/**
 * made a change on the panzoom code so the component can be used with right button
 * var isLeftButton =
      (e.button === 2 && window.event !== null) || e.button === 2;
    if (!isLeftButton) return;
 */
export default {
   computed: {
      pz_options() {
         return {
            initialZoom: 1,
            minZoom: 0.3,
            maxZoom: 10,
            boundsPadding: 0.1,
         };
      },
      pz_moveToTarget() {
         return this.$store.getters.systemView_pz_moveToTarget;
      },
   },
   watch: {
      pz_moveToTarget(val) {
         this.pz_moveTo(val.x, val.y, val.w, val.h);
      },
   },
   data() {
      return {
         pz_element: null,
         pz_element_id: null,
         pz_disableSlider: false,
         imageSize: {
            width: 0,
            height: 0,
         },
         systemViewContainerSize: {
            width: 0,
            height: 0,
         },
         panzoomContainerSize: {
            width: 0,
            height: 0,
         },
      };
   },
   mounted() {
      this.pz_resetPanZoom();
   },
   destroyed() {
      this.pz_element = null;
      this.pz_element_id = null;
   },

   methods: {
      pz_onPanZoomInit(e, id) {
         this.pz_element = e;
         this.pz_element_id = id;
         this.pz_element.on('transform', (e) => this.pz_onEnd(e));
         this.pz_element.on('panend', (e) => this.pz_onEnd(e));
         this.pz_element.on('zoomend', (e) => this.pz_onEnd(e));
         this.pz_element.on('panstart', (e) => this.pz_onStart(e));
         this.pz_element.on('pan', (e) => this.pz_onStart(e));
         if (this.$refs._img) {
            this.imgElement = this.$refs._img;
         } else {
            this.imgElement.image = null;
         }
         this.pz_updatepanZoomContainerSize(null);
      },
      pz_die() {
         this.pz_element.fire('mouseup');
         this.pz_mouseUp();
      },
      pz_updatepanZoomContainerSize(e = null) {
         if (e !== null) {
            if (e.propertyName !== 'opacity') {
               return;
            }
         }
         if (this.imgElement !== null) {
            if ((this.imgElement.image !== null) && (this.$refs.systemViewContainer)) {
               this.imageSize.width = this.imgElement.image.width;
               this.imageSize.height = this.imgElement.image.height;
               this.systemViewContainerSize.width = this.$refs.systemViewContainer.clientWidth;
               this.systemViewContainerSize.height = this.$refs.systemViewContainer.clientHeight;

               this.containerSize.width = this.imgElement.image.width;
               this.containerSize.height = this.imgElement.image.height;

               this.panzoomContainerSize.width = this.systemViewContainerSize.width;
               this.panzoomContainerSize.height = this.systemViewContainerSize.height - 64;
               document.querySelector('.vue-pan-zoom-scene').style.height = `${this.panzoomContainerSize.height}px`;
            }
         }
      },
      pz_mouseDown() {
         this.pz_cursorMove = true;
      },
      pz_mouseUp() {
         this.pz_cursorMove = false;
      },
      pz_resetPanZoom() {
         if (this.pz_element) {
            const z = (this.currCompSize == 'large' ? 1 : 0.3);
            setTimeout(() => {
               this.pz_element.moveTo(0, 0);
            }, 10);
            setTimeout(() => {
               this.pz_element.zoomAbs(0, 0, z);
            }, 20);
            this.zoomSlider.value = 1;
         }
      },
      pz_onEnd() {
         this.zoomSlider.value = Math.round(this.pz_element.getTransform().scale * 100) / 100;
         this.pz_disableSlider = false;
      },
      pz_onStart() {
         this.pz_disableSlider = true;
      },
      pz_doZoom(val) {
         const compScale = (this.currCompSize === 'large') ? 0.7 : 0.4;
         this.pz_element.smoothZoom((window.innerWidth / 2) * compScale, (window.innerHeight / 2) * compScale, val / this.pz_element.getTransform().scale);
      },
      pz_moveTo(x, y, w, h) {
         const pz_scale = this.pz_element.getTransform().scale;
         const compScale = (this.currCompSize === 'large') ? 0.7 : 0.3;
         this.pz_element.smoothMoveTo((-(x) - (w / 2)) * pz_scale + ((window.innerWidth / 2) * compScale), (-(y) - (h / 2)) * pz_scale + ((window.innerHeight / 2) * compScale));
      },
      pz_moveToXY(x, y) {
         this.pz_element.smoothMoveTo(x, y);
      },

   },
};
